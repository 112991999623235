import { Link, NavLink } from 'react-router-dom';
import logo from '../../../assets/Images/logo-bw.png';
import facebook from '../../../assets/Images/facebook.png';

import './footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container-lg py-5'>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-md-8 col-lg-3 my-3 my-sm-3 my-lg-0 text-center text-sm-start'>
                        <NavLink to="" className='d-none d-sm-flex align-items-center text-decoration-none px-2'>
                            <img src={logo} alt="" height={50} width={65} />
                            <div>
                                <h3 className='brand-footer'>shlok shakti</h3>
                                <h5 className='brand-sm-footer m-0'>estate</h5>
                            </div>
                        </NavLink>
                        <NavLink to="home" className='d-sm-none d-flex align-items-center justify-content-center text-decoration-none px-2'>
                            <img src={logo} alt="" height={50} width={65} />
                            <div>
                                <h3 className='brand-footer'>shlok shakti</h3>
                                <h5 className='brand-sm-footer m-0'>estate</h5>
                            </div>
                        </NavLink>
                        <p className='mt-4 pe-sm-4'>
                            Your Trusted Name in Gandhinagar and Ahmedabad Real Estate. Experience Excellence in Property Solutions with Mr. Pradip Patel.
                        </p>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4 col-lg-2 my-3 my-sm-3 my-lg-0 text-center text-sm-start'>
                        <h5 className='mb-3 mb-sm-4 mb-lg-5'>Pages</h5>
                        <Link to="home" className='text-decoration-none text-light fs-6 d-block mb-3'>Home</Link>
                        <Link to="about" className='text-decoration-none text-light fs-6 d-block mb-3'>About</Link>
                        <Link to="properties" className='text-decoration-none text-light fs-6 d-block mb-3'>Property Listing</Link>
                        <Link to="contact-us" className='text-decoration-none text-light fs-6 d-block mb-3'>Contact Us</Link>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 col-lg-2 my-3 my-sm-3 my-lg-0 text-center text-sm-start'>
                        <h5 className='mb-3 mb-sm-4 mb-lg-5'>Service</h5>
                        <Link to="sell-property" className='text-decoration-none text-light fs-6 d-block mb-3'>Sell/Rent Property </Link>
                        <Link to="properties" className='text-decoration-none text-light fs-6 d-block mb-3'>Buy Property</Link>
                    </div>
                    <div className='col-12 col-sm-6 col-md-5 col-lg-3 my-3 my-sm-3 my-lg-0 text-center text-sm-start'>
                        <h5 className='mb-3 mb-sm-4 mb-lg-5'>Contact</h5>
                        <div className='d-flex d-sm-block align-items-center justify-content-center'>
                            <div className='d-flex'>
                                <i className="bi bi-telephone-fill me-3"></i>
                                <a href="tel:+91 9537853025" target='blank' className='text-white text-decoration-none mb-3 d-block'>
                                    +91 9537853025
                                </a>
                            </div>
                        </div>
                        <div className='d-flex d-sm-block align-items-center justify-content-center'>
                            <div className='d-flex'>
                                <i className="bi bi-envelope-fill me-3"></i>
                                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=Pradip158@Gmai.com" target='blank' className='text-white text-decoration-none mb-3 d-block'>
                                    Pradip158@Gmai.com
                                </a>
                            </div>
                        </div>
                        <div className='d-inline d-sm-flex justify-content-center'>
                            <i className="d-inline d-sm-flex bi bi-geo-alt-fill me-3 mt-1"></i>
                            <a href='https://maps.google.com/?q=Shlok Shakti, Kudasan, Gandhinagar, Gujarat 382421' target='blank' className='text-decoration-none text-white d-inline d-sm-flex'>East 415, Siddhraj z square , Opp the landmark, Podar international school road, Kudasan, Gandhinagar</a>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4 col-lg-2 my-3 my-sm-3 my-lg-0 text-center text-sm-start'>
                        <h5 className='mb-3 mb-sm-4 mb-lg-5'>Social Media</h5>
                        <div className='d-flex d-sm-block align-items-center justify-content-center'>
                            <div className='d-flex align-items-center'>
                                <a href="https://www.facebook.com/ShlokShaktiEstate/" target='blank' className='me-4' >
                                    <img src={facebook} height={24} alt="" />
                                </a>
                                <a href="https://twitter.com/PradipP36334477" target='blank' className='me-4'>
                                    <i className="bi bi-twitter text-light fs-4"></i>
                                </a>
                                <a href="https://www.linkedin.com/in/pradip-patel-rera-certified-property-consultant-b2a25a9a/" target='blank'>
                                    <i className="bi bi-linkedin text-light fs-4"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='treadmark'>
                <div className='container-md text-center text-sm-start d-sm-flex flex-wrap justify-content-between align-items-center'>
                    <div className='d-sm-flex'>
                        <h6>© 2023 Shlok Shakti Estate </h6><h6 className='d-none d-sm-inline mx-2'> | </h6><h6> Powered by Shlok Shakti Estate</h6>
                    </div>
                    <h6>Design and Developed by <Link to={'http://flitzinteractive.com'} target='blank' className='text-decoration-none text-yellow'>Flitz Interactive</Link></h6>
                </div>
            </div>
        </div>
    );
}

export default Footer;

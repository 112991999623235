/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './properties-admin.css';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import { AgGridReact } from 'ag-grid-react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../components/loader/loader';
import Swal from 'sweetalert2';

const PropertiesAdmin = () => {

    const gridRef = useRef();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [loading, setLoading] = useState(true);
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        cellClass: 'no-border'
    }));

    useEffect(() => {
        getPropertyData()
    }, [])

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    setRowData(response.data);
                }
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })

        setColumnDefs([
            { headerName: 'Title', field: 'title', minWidth: 200, maxWidth: 300 },
            { headerName: 'Subtitle', field: 'subtitle', minWidth: 200 },
            { headerName: 'Location', field: 'location', minWidth: 220, maxWidth: 260 },
            { headerName: 'Sell/Rent', field: 'sell_rent', minWidth: 140, maxWidth: 150 },
            { headerName: 'Property type', field: 'property_type', minWidth: 160, maxWidth: 200 },
            { headerName: 'Status', field: 'status', minWidth: 115, maxWidth: 120 },
            {
                headerName: 'Actions', minWidth: 130, maxWidth: 180, sortable: false, filter: false, cellRenderer: params => {
                    return (
                        <div>
                            <i className="bi bi-eye me-3 fs-5 text-primary" role='button' onClick={() => { handleView(params.data) }}></i>
                            <i className="bi bi-trash me-3 fs-5 text-danger" role='button' onClick={() => { handleDelete(params.data) }}></i>
                            <Link to={`/admin/view-property/${params.data.title?.replace(/\s+/g, '-')}`} state={{ isEdit: true }} target='_blank' className="bi bi-pencil-square fs-5 text-black" ></Link>
                            {/* <i className="bi bi-pencil-square fs-5 text-black" role='button' onClick={() => { handleEdit(params.data) }}></i> */}
                        </div >
                    )
                }
            },
        ])
    }

    const deleteImages = async (imageData) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyImages/${imageData._id}`)
            .then(res => {
                setLoading(false);
                res.data?.forEach(async (element) => {
                    await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteImageFile/${element.id}`)
                        .then(response => {
                            setLoading(false);
                            if (response.data.error) {
                                errorToaster(response.data.message)
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            errorToaster(error)
                        })
                });
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const deleteVideos = async (videoData) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyVideos/${videoData._id}`)
            .then(res => {
                setLoading(false);
                res.data?.forEach(async (element) => {
                    await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteVideoFile/${element.id}`)
                        .then(response => {
                            setLoading(false);
                            if (response.data.error) {
                                errorToaster(response.data.message)
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            errorToaster(error)
                        })
                })
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const navigateTo = useCallback(event => {
        if (event.colDef.headerName !== "Actions") {
            let trimmedTitle = event.data.title?.replace(/\s+/g, '-');
            window.open(`/admin/view-property/${trimmedTitle}`, "_blank")
        }
    }, [])

    // const handleEdit = (data) => {
    //     navigate(`/admin/view-property/${data.title?.replace(/\s+/g, '-')}`, { state: { isEdit: true } })
    // }

    const handleView = (data) => {
        window.open(`/property-details/${data.title?.replace(/\s+/g, '-')}`, "_blank")
    }

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteProperty/${data._id}`)
                    .then(async (response) => {
                        setLoading(false);
                        if (response.data.error) {
                            errorToaster(response.data.message)
                        } else {
                            successToaster(response.data.message)
                            navigate('/admin/dashboard');
                            deleteImages(data);
                            deleteVideos(data);
                        }
                    }).catch(error => {
                        setLoading(false);
                        errorToaster(error)
                    })
            } else {
                return;
            }
        })
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className="d-flex py-2 px-3 admin-title align-items-center justify-content-between" >
                    <h4 className="pl-3 mb-0 pb-0">Property List</h4>
                    <i className="bi bi-plus-square fs-3 add-admin" role='button' onClick={() => navigate('/admin/add-property')}></i>
                </div>
                <div className="ag-theme-alpine" style={{ width: '100%', height: 'calc(100vh - 158px)' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        paginationAutoPageSize={true}
                        pagination={true}
                        paginationPageSize={20}
                        animateRows={true}
                        rowSelection='multiple'
                        onCellClicked={navigateTo}
                    />
                </div>
            </div>
        </div >
    );
}

export default PropertiesAdmin;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAuth, { AuthProvider } from './auth/useAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import './index.css';
import User from './layout/user/app/App';
import About from './pages/about/about';
import Home from './pages/home/home';
import PropertyDetails from './pages/property-details/property-details';
import Properties from './pages/property-listing/property-listing';
import SellProperty from './pages/sell-property/sell-property';
import MapView from './pages/map-view/map-view';
import ContactUs from './pages/contact-us/contact-us';
import Admin from './layout/admin/app/App';
import Login from './pages/login/login';
import Register from './pages/register/register';
import Users from './pages/users/users';
import Dashboard from './pages/dashboard/dashboard';
import AddProperty from './pages/add-property/add-property';
import PropertiesAdmin from './pages/properties-admin/properties-admin';
import ViewProperty from './pages/view-property/view-property';
import ContactAdmin from './pages/contact-admin/contact-admin';
import PropertyRequests from './pages/property-requests/property-request';
import ViewPropertyRequest from './pages/view-property-request/view-property-request';
import Feedback from './pages/feedback/feedback';
import AddFeedback from './pages/add-feedback/add-feedback';
import NotFoundPage from './components/not-found-page/not-found-page';
import SliderImageSetting from './pages/slider-image-setting/slider-image-setting';
import { ToastContainer } from 'react-toastify';

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-toastify/dist/ReactToastify.css';

// Fonts
import "@fontsource/mulish";
import "@fontsource/mulish/700.css";
import "@fontsource/inter";
import "@fontsource/inter/700.css";
import "@fontsource/playfair/700.css";
import GiftcityProperty from './pages/giftcity-property/giftcity-property';

const root = ReactDOM.createRoot(document.getElementById('root'));




const RequireAuth = ({ children }) => {
  const { authed } = useAuth();
  const location = useLocation();
  const data = localStorage.getItem('apiKey');
  var jwtPayload = 0;
  jwtPayload = data ? JSON.parse(window.atob(data.split('.')[1])) : 0;
  const isTokenInvalid = Date.now() >= jwtPayload.exp * 1000;

  return authed === true ? (
    children
  ) : data && !isTokenInvalid ? (children) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

const RequireLogout = ({ children }) => {
  const { authed } = useAuth();
  const location = useLocation();
  const data = localStorage.getItem('apiKey');
  var jwtPayload = 0;
  jwtPayload = data ? JSON.parse(window.atob(data.split('.')[1])) : 0;
  const isTokenInvalid = Date.now() >= jwtPayload.exp * 1000;

  return authed === true ? (
    children
  ) : data && !isTokenInvalid ? (
    <Navigate to="/admin" replace state={{ path: location.pathname }} />
  ) : (children);
}

root.render(
  <AuthProvider>
    <BrowserRouter>
      <ToastContainer />
      <HelmetProvider>
        <Helmet>
          <title>Shlok Shakti Real Estate</title>
          <meta name='description' content='Finest property solution for Ahmedabad & Gandhinagar' />
        </Helmet>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="" element={<User />} >
            <Route index path="" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/giftcity-property" element={<GiftcityProperty />} />
            <Route path="/property-details/:id" element={<PropertyDetails />} />
            <Route path="/sell-property" element={<SellProperty />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
          <Route path="/admin" element={<RequireAuth> <Admin /> </RequireAuth>} >
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="add-property" element={<AddProperty />} />
            <Route path="properties-admin" element={<PropertiesAdmin />} />
            <Route path="view-property/:id" element={<ViewProperty />} />
            <Route path="property-requests" element={<PropertyRequests />} />
            <Route path="view-property-request/:id" element={<ViewPropertyRequest />} />
            <Route path="contact-admin" element={<ContactAdmin />} />
            <Route path="add-feedback" element={<AddFeedback />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="slider-setting" element={<SliderImageSetting />} />
          </Route>
          <Route path="/login" element={<RequireLogout><Login /></RequireLogout>} />
          <Route path="/register" element={<RequireAuth><Register /></RequireAuth>} />
          <Route path="/map-view" element={<MapView />} />
        </Routes>
      </HelmetProvider>
    </BrowserRouter>
  </AuthProvider>
);

import RotateLoader from "react-spinners/RotateLoader";
import './loader.css'

const Loader = (props) => {

    const color = '#CE9931'

    return (
        <div className="loader-background" style={{ height: `${props && props.height ? props.height : 'calc(100% - 66px)'}`, position: `${props && props.position ? props.position : 'fixed'}` }}>
            <RotateLoader
                color={color}
                margin={5}
                size={18}
            />
        </div>
    )
};

export default Loader;
/* eslint-disable jsx-a11y/iframe-has-title */
import './contact-us.css';
import facebook from '../../assets/Images/facebook-yellow.jpg';
import { useState } from 'react';
import { contactUsValidation } from '../../validations/validation';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const ContactUs = () => {

    const initcontactForm = { firstName: '', lastName: '', email: '', phone: '', message: '' };
    const [contactUsData, setData] = useState(initcontactForm);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const handleChange = (e) => {
        setData({ ...contactUsData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(contactUsValidation(contactUsData));
        if (isEmpty(contactUsValidation(contactUsData))) {
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_SERVER_CONTACTUS}/postContactUsData`, contactUsData)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster(response.data.message)
                        setData(initcontactForm);
                    }
                }).catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        } else {
            return
        }
    }

    return (
        <div className='mt-66 pb-5'>
            {loading ? <Loader /> : ''}
            <Helmet>
                <meta name="title" content="Contact - Shlok Shakti" />
                <meta name="description" content="Contcat shlok shakti, we are ready to help you." />
                <meta name="keywords" content="Shlok Shakti, Gandhinagar, Ahmedabad, Real estate," />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <link rel="canonical" href={window.location.href} />
                <meta property="og:title" content="Contact - Shlok Shakti" />
                <meta property="og:type" content="Reale state" />
                <meta property="og:description" content="Contcat shlok shakti, we are ready to help you." />
                <meta property="og:url" content="http://sse.flitzinteractive.com/contact-us" />
                <meta property="og:image" content="http://sse.flitzinteractive.com/logo192.png" />
            </Helmet>

            <div className='text-center pt-3 container-lg'>
                <span className='text-yellow fs-5'>Contact Us</span>
                <h1 className='my-2 text-black'>Get in Touch</h1>
            </div>
            <div className='card mb-5 mt-4 container-lg'>
                <div className='row'>
                    <div className='col-12 col-md-5'>
                        <div className='col-12 rounded-2 bg-yellow p-4 p-lg-5 text-light'>
                            <div className='mb-5'>
                                <h4>Contact Information</h4>
                                <p>Our Consultations Are Complimentary.</p>
                            </div>
                            <div className='mb-5'>
                                <div className='d-flex align-items-center me-5 mt-4'>
                                    <i className="bi bi-telephone fs-3"></i>
                                    <div className='ms-3'>
                                        <h6 className='fw-bold mb-1'>PHONE</h6>
                                        <a href="tel:+91 9537853025" target='blank' className='text-white text-decoration-none mb-2 d-block' style={{ lineHeight: '1.2' }}>
                                            +91 9537853025
                                        </a>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <i className="bi bi-geo-alt fs-3"></i>
                                    <div className='ms-3'>
                                        <h6 className='fw-bold mb-1'>ADDRESS</h6>
                                        <a href='https://maps.google.com/?q=Shlok Shakti, Kudasan, Gandhinagar, Gujarat 382421' target='blank' className='text-decoration-none text-white ff-inter mb-2 d-block' style={{ lineHeight: '1.2' }}>East 415, Siddhraj z square , Opp the landmark, Podar international school road, Kudasan, Gandhinagar</a>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <i className="bi bi-envelope-paper fs-3"></i>
                                    <div className='ms-3'>
                                        <h6 className='fw-bold mb-1'>EMAIL</h6>
                                        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=pradip158@gmail.com" target='blank' className='text-white text-decoration-none mb-2 d-block' style={{ lineHeight: '1.2' }}>
                                            pradip158@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex pt-0 pt-md-5'>
                                <a href="https://www.facebook.com/ShlokShaktiEstate/" target='blank' className='social-icon-box me-4'>
                                    <img height={22} width={16} src={facebook} alt="" />
                                </a>
                                <a href="https://twitter.com/PradipP36334477" target='blank' className='social-icon-box me-4'>
                                    <i className="bi bi-twitter text-yellow fs-5"></i>
                                </a>
                                <a href="https://www.linkedin.com/in/pradip-patel-rera-certified-property-consultant-b2a25a9a/" target='blank' className='social-icon-box'>
                                    <i className="bi bi-linkedin text-yellow fs-5"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-7 p-0'>
                        <form onSubmit={handleSubmit} method='POST' className='row m-0 py-4 ps-md-2 pe-md-3 py-md-4 ps-lg-3 pe-lg-4 py-lg-5 mt-4 mt-md-0'>
                            <div className='col-12 col-md-6'>
                                <label htmlFor="firstName" className="label">First Name</label>
                                <input type="text" id='firstName' name='firstName' value={contactUsData.firstName} className={"input mb-4 " + (errors.firstName ? 'error-input' : '')} onChange={handleChange} placeholder='Enter First Name' />
                                {errors.firstName ?
                                    <span className='error-font'>{errors.firstName}</span>
                                    : ''
                                }
                            </div>
                            <div className='mb-2 mb-md-4 col-12 col-md-6'>
                                <label htmlFor="lastName" className="label">Last Name</label>
                                <input type="text" id='lastName' name='lastName' value={contactUsData.lastName} className={"input mb-4 " + (errors.lastName ? 'error-input' : '')} onChange={handleChange} placeholder='Enter Last Name' />
                                {errors.lastName ?
                                    <span className='error-font'>{errors.lastName}</span>
                                    : ''
                                }
                            </div>
                            <div className='mb-2 mb-md-4 col-12 col-md-6'>
                                <label htmlFor="email" className="label">Email</label>
                                <input type="email" id='email' name='email' value={contactUsData.email} className={"input mb-4 " + (errors.email ? 'error-input' : '')} onChange={handleChange} placeholder='Enter Email' />
                                {errors.email ?
                                    <span className='error-font'>{errors.email}</span>
                                    : ''
                                }
                            </div>
                            <div className='mb-2 mb-md-4 col-12 col-md-6'>
                                <label htmlFor="phoneNumber" className="label">Phone Number</label>
                                <input type="number" id='phoneNumber' name='phone' value={contactUsData.phone} className={"input mb-4 " + (errors.phone ? 'error-input' : '')} onChange={handleChange} placeholder='Enter Phone number' />
                                {errors.phone ?
                                    <span className='error-font'>{errors.phone}</span>
                                    : ''
                                }
                            </div>
                            <div className='mb-2 mb-md-4 col-12'>
                                <label htmlFor="message" className="label">Message</label>
                                <textarea rows={3} id='message' name='message' value={contactUsData.message} className="input mb-4 " onChange={handleChange} placeholder='Write Your Message...' />
                            </div>
                            <div className='d-flex justify-content-end pt-4'>
                                <button className='btn btn-yellow' type='submit'>Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='mb-5 container-lg'>
                <iframe src="https://www.google.com/maps/embed/v1/place?q=https://www.google.com/maps/place/Siddhraj+Z+Square/@23.1754244,72.6297695,202m/data=!3m1!1e3!4m6!3m5!1s0x395c2b8e0f91c2f3:0x16020edeb0898c42!8m2!3d23.1756183!4d72.629568!16s%2Fg%2F11fkbxmtth?entry=ttu&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" style={{ height: '400px', width: '100%' }} />
            </div>
        </div>
    );
}

export default ContactUs;

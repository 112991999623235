/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from 'react-router-dom';
import './view-property-request.css';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { DefaultPlayer as Video } from 'react-html5video';
import Modal from 'react-bootstrap/Modal';
import Whirligig from 'react-whirligig';
import Loader from '../../components/loader/loader';

const ViewPropertyRequest = () => {

    let { id } = useParams();
    const [propertyRequestDetails, setPropertyRequestDetails] = useState();
    const [propertyImages, setPropertyImages] = useState();
    const [propertyVideos, setPropertyVideos] = useState();
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPropertyRequestData();
        getPropertyImages();
        getPropertyVideos();
    }, [])

    const getPropertyRequestData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/getPropertyRequestsById/${id}`)
            .then(res => {
                setLoading(false);
                setPropertyRequestDetails(res.data[0])
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyImages = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/getPropertyImages/${id}`)
            .then(res => {
                setLoading(false);
                setPropertyImages(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyVideos = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/getPropertyVideos/${id}`)
            .then(res => {
                setLoading(false);
                setPropertyVideos(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const handleDenied = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, denied it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/deletePropertyRequest/${id}`,)
                    .then(async (response) => {
                        setLoading(false);
                        if (response.data.error) {
                            errorToaster(response.data.message)
                        } else {
                            successToaster(response.data.message)
                            navigate(-1);
                            if (propertyImages.length) {
                                await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/deleteImageFiles/${id}`)
                                .then(response => {
                                    setLoading(false);
                                    if (response.data.error) {
                                            errorToaster(response.data.message)
                                        }
                                    })
                                    .catch(error => {
                                        setLoading(false);
                                        errorToaster(error)
                                    })
                            }
                            if (propertyVideos.length) {
                                await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/deleteVideoFiles/${id}`)
                                    .then(response => {
                                        setLoading(false);
                                        if (response.data.error) {
                                            errorToaster(response.data.message)
                                        }
                                    })
                                    .catch(error => {
                                        setLoading(false);
                                        errorToaster(error)
                                    })
                            }
                        }
                    }).catch(error => {
                        setLoading(false);
                        errorToaster(error)
                    })
            } else {
                return;
            }
        })
    }

    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();

    const handleCloseModal = () => setShow(false);
    const handleShowImage = () => setShow(true);

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className='mb-3 mb-md-0'>
                    <i className="bi bi-chevron-left back-icon" role="button" onClick={() => { navigate(-1) }}></i>
                </div>
                <div className='card col-12 col-md-10 col-lg-9 col-xl-8 mx-auto bg-white p-4 rounded-3'>
                    <div className='row'>
                        <h4 className='text-center py-3 mb-3'>Property details</h4>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Name</h6>
                            <input type="text" className='input' value={propertyRequestDetails?.name ? propertyRequestDetails?.name : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Phone</h6>
                            <input type="text" className='input' value={propertyRequestDetails?.phone ? propertyRequestDetails?.phone : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Sell/Rent</h6>
                            <input type="text" className='input' value={propertyRequestDetails?.sell_rent ? propertyRequestDetails?.sell_rent : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Property type</h6>
                            <input type="text" className='input' value={propertyRequestDetails?.property_type ? propertyRequestDetails?.property_type : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Address</h6>
                            <textarea type="text" rows={3} className='input' value={propertyRequestDetails?.address ? propertyRequestDetails?.address : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <h6 className='label'>Property details</h6>
                            <textarea type="text" rows={3} className='input' value={propertyRequestDetails?.property_details ? propertyRequestDetails?.property_details : ''} readOnly />
                        </div>

                        <div className='d-flex flex-wrap mt-2'>
                            {
                                propertyImages?.length ?
                                    propertyImages.map((file, index) => (
                                        <img key={index} src={file.filePath} className='image-box border border-secondary mb-3' alt="" onClick={handleShowImage} role='button' />
                                    )) : ''
                            }
                            {
                                propertyVideos?.length ?
                                    propertyVideos.map((file, index) => (
                                        <div key={index} className="mb-3" onClick={handleShowImage} role='button' >
                                            <Video className="image-box border border-secondary" controls={[]} muted>
                                                <source className='h-100 w-100' src={file.filePath} />
                                            </Video>
                                        </div>
                                    )) : ''
                            }
                        </div>

                        <Modal show={show} onHide={handleCloseModal} className='view-images-modal' centered>
                            <Whirligig
                                visibleSlides={1}
                                ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
                                preventScroll='false'
                                className="rounded slider-box"
                                style={{ maxHeight: 'calc(100vh - 100px)' }}
                            >
                                {
                                    propertyImages?.map((res, index) => (
                                        <img key={index} className='h-100 w-100 object-fit-contain' src={res?.filePath} alt="" />
                                    ))
                                }
                                {
                                    propertyVideos?.map((res, index) => (
                                        <Video key={index} className="h-100 w-100 object-fit-contain" autoPlay loop controls={[]} muted>
                                            <source src={res.filePath} type='video/mp4' />
                                        </Video>
                                    ))
                                }
                            </Whirligig>
                            <i onClick={prev} className='indicator-l bi bi-caret-left fs-3' role='button'></i>
                            <i onClick={next} className='indicator-r bi bi-caret-right fs-3' role='button'></i>
                            <a href='property images' download={propertyImages && propertyImages[0]?.filePath}>
                                <i className='download-file bi bi-download fs-5'></i>
                            </a>
                        </Modal>

                        {
                            propertyRequestDetails?.status === 'approved' ?
                                <div className='d-flex align-items-center justify-content-end'>
                                    <h5 className='text-success'>Approved!</h5>
                                </div> :
                                <div className='col-12 text-end'>
                                    <button className='btn btn-primary me-3' onClick={() => { navigate('/admin/add-property', { state: { id: id, propertyRequestDetails: propertyRequestDetails } }) }}>Approve</button>
                                    <button className='btn btn-danger' onClick={handleDenied}>Denied</button>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ViewPropertyRequest;
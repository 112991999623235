import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../../assets/Images/logo.png';
import './header.css';
import { useState } from 'react';

const Header = () => {

    const navigate = useNavigate();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => { setIsNavCollapsed(!isNavCollapsed) };
    const handleNavigateToHome = () => {
        navigate('/');
        window.location.reload();
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className='container-lg d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center text-decoration-none' role='button' onClick={handleNavigateToHome}>
                    <img src={logo} alt="" height={50} width={65} />
                    <div>
                        <h3 className='brand'>shlok shakti</h3>
                        <h5 className='brand-sm m-0'>estate</h5>
                    </div>
                </div>
                <button className="navbar-toggler text-black" type="button" onClick={handleNavCollapse} data-toggle="collapse"
                    data-target="#navbarSupportedContent">
                    {isNavCollapsed ? <i className="bi bi-list"></i> : <i className="bi bi-x-circle"></i>}
                </button>

                <div className="justify-content-end d-lg-flex d-none">
                    <ul className="navbar-nav">
                        <li className="nav-item active py-2">
                            <NavLink to="/" className="text-decoration-none black-text me-5 fs-18">
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="about" className="text-decoration-none black-text me-5 fs-18">
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="properties" className="text-decoration-none black-text me-5 fs-18">
                                All Properties
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="sell-property" className="text-decoration-none black-text me-5 fs-18">
                                Sell / Rent
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="giftcity-property" className="text-decoration-none black-text me-5 fs-18">
                                Gift City
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="contact-us" className="text-decoration-none black-text fs-18">
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-collapse collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item active py-2">
                            <NavLink to="/" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text me-5 d-flex">
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="about" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text me-5 d-flex">
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="properties" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text me-5 d-flex">
                                All Properties
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="sell-property" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text me-5 d-flex">
                                Sell / Rent
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="giftcity-property" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text me-5 d-flex">
                                Gift City
                            </NavLink>
                        </li>
                        <li className="nav-item active py-2">
                            <NavLink to="contact-us" data-toggle="collapse" data-target="#navbarSupportedContent"
                                onClick={() => { setIsNavCollapsed(true) }} className="text-decoration-none black-text d-flex">
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './header.css';

import useAuth from '../../../auth/useAuth';
import Swal from 'sweetalert2';

const Header = (props) => {

    const [isOpen, setOpen] = useState(false);
    const { logout } = useAuth();
    const navigate = useNavigate();


    const toggleSidebar = () => {
        setOpen(!isOpen);
        props.toggle(isOpen)
    }

    const handleLogout = () => {
        Swal.fire({
            title: 'Confirm logout',
            text: "Are you sure you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
                localStorage.clear();
                navigate('/login')
            } else {
                return;
            }
        })
    };

    return (
        <div className="nav-bar-content py-3 px-4">
            <div className="d-flex align-items-center">
                <i className="bi bi-list me-3 d-none d-sm-block" onClick={toggleSidebar} role="button"></i>
                <h5 className="m-0 fw-bold text-capitalize">{props.userData.name}</h5>
            </div>
            <Link onClick={handleLogout} className='text-decoration-none logout fw-bold'>
                Logout
            </Link>
        </div>
    )
}

export default Header
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import './add-feedback.css';
import axios from 'axios';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import { useNavigate } from 'react-router-dom';
import { feedbackValidation } from '../../validations/validation';
import Loader from '../../components/loader/loader';
import { Rating } from 'react-simple-star-rating';

const AddFeedback = () => {

    const initFeedbackForm = { name: '', rating: 0, feedback: '' };
    const [feedbackData, setData] = useState(initFeedbackForm);
    const [profileImage, setProfileImage] = useState();
    const [profileImageFile, setProfileImageFile] = useState({});
    const [errors, setErrors] = useState({});
    const [ratingError, setRatingError] = useState('');
    const [fileError, setFileError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setData({ ...feedbackData, [e.target.name]: e.target.value });
    }

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const getImagePath = (e) => {
        let seletedFile = e.target.files;
        if (seletedFile[0]) {
            if (seletedFile[0].size < 5242880) {
                if (seletedFile[0].type === 'image/jpg' || seletedFile[0].type === 'image/png' || seletedFile[0].type === 'image/jpeg') {
                    setProfileImageFile(seletedFile[0])
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setProfileImage(e.target.result);
                    }
                    reader.readAsDataURL(seletedFile[0])
                } else {
                    errorToaster('Invalid image type.')
                }
            } else {
                errorToaster('File size maximum 5mb required.')
            }
        } else {
            return;
        }
    }

    const handleRating = (rate) => {
        setData({ ...feedbackData, rating: rate })
    }

    const handleSubmit = async (e) => {
        setErrors(feedbackValidation(feedbackData));
        setRatingError('Rating is required.');
        setFileError('Profile image is required.');
        e.preventDefault();
        if (feedbackData.rating !== 0 && isEmpty(feedbackValidation(feedbackData)) && profileImageFile.length !== 0) {
            const formData = new FormData();
            formData.append('profileImage', profileImageFile)
            formData.append('feedbackData', JSON.stringify(feedbackData))
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_SERVER_FEEDBACK}/postFeedback`, formData)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster(response.data.message)
                        navigate('/admin/feedback');
                    }
                }).catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        } else {
            return
        }
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className="col-12 col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto p-3">
                <div className="card p-4 rounded-2">
                    <h2 className="mb-4 mt-2 text-dark-blue fw-bold text-center">Add feedback</h2>
                    <form onSubmit={handleSubmit} method='POST'>
                        <div className='d-flex flex-wrap'>
                            <div className='me-3'>
                                <label htmlFor='image_url' className='profile-box d-flex align-items-center justify-content-center mb-4' role='button'>
                                    <i className="bi bi-card-image"></i>
                                    <input type="file" name="imageUrl" accept="image/png, image/jpeg, image/jpg" onChange={getImagePath} id="image_url" className='d-none' />
                                </label>
                                {fileError && !profileImage ?
                                    <span className='error-font'>{fileError}</span>
                                    : ''
                                }
                            </div>
                            {
                                profileImage ?
                                    <div className='position-relative'>
                                        <img src={profileImage} className='profile-box mb-3' alt="" />
                                    </div> : ""
                            }
                        </div>
                        <div>
                            <label htmlFor="name" className="label">Name</label>
                            <input type='text' placeholder='Name' id='name' onChange={handleChange} value={feedbackData.name} name='name' className='col-12 input mb-4' />
                            {errors.name ?
                                <span className='error-font'>{errors.name}</span>
                                : ''
                            }
                        </div>
                        <div>
                            <label htmlFor="feedback" className="label">Feedback</label>
                            <textarea type='text' rows={3} placeholder='Feedback' id='feedback' onChange={handleChange} value={feedbackData.feedback} name='feedback' className='col-12 input mb-4' />
                            {errors.feedback ?
                                <span className='error-font'>{errors.feedback}</span>
                                : ''
                            }
                        </div>
                        <Rating
                            onClick={handleRating}
                            className='mb-4'
                        />
                        {ratingError && feedbackData.rating === 0 ?
                            <span className='error-font'>{ratingError}</span>
                            : ''
                        }
                        <div className="w-100 pt-3 text-end">
                            <button type='button' className='mb-2 btn btn-danger' onClick={() => { navigate(-1) }}>Cancel</button>
                            <button type='submit' className='mb-2 btn btn-primary ms-3'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddFeedback;
/* eslint-disable jsx-a11y/iframe-has-title */
import facebook from '../../assets/Images/facebook.png';

import './contact-us-section.css';
import { Link } from 'react-router-dom';

const ContactUsSection = (props) => {

    return (
        <div className='container-lg py-5'>
            <div className='text-center'>
                <span className='text-yellow fs-5'>{props.title}</span>
                <h1 className='my-2 text-black'>{props.subTitle}</h1>
            </div>
            <div className='row py-5 flex-row-reverse'>
                <div className='col-12 col-md-6'>
                    {/* <iframe src="https://www.google.com/maps/place/Siddhraj+Z+Square/@23.1754244,72.6297695,202m/data=!3m1!1e3!4m6!3m5!1s0x395c2b8e0f91c2f3:0x16020edeb0898c42!8m2!3d23.1756183!4d72.629568!16s%2Fg%2F11fkbxmtth?entry=ttu" style={{ height: '100%', width: '100%' }} /> */}
                    <iframe src="https://www.google.com/maps/embed/v1/place?q=https://www.google.com/maps/place/Siddhraj+Z+Square/@23.1754244,72.6297695,202m/data=!3m1!1e3!4m6!3m5!1s0x395c2b8e0f91c2f3:0x16020edeb0898c42!8m2!3d23.1756183!4d72.629568!16s%2Fg%2F11fkbxmtth?entry=ttu&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" style={{ height: '100%', width: '100%' }} />
                </div>
                <div className='col-12 col-md-6'>
                    <div className='d-flex flex-wrap'>
                        <div className='d-flex me-5 mt-4'>
                            <i className="bi bi-telephone fs-3"></i>
                            <div className='ms-3'>
                                <h6 className='fw-bold mb-1 ff-inter'>PHONE</h6>
                                <a href="tel:+91 9537853025" target='blank' className='text-yellow ff-inter text-decoration-none mb-2 d-block' style={{ lineHeight: '1.2' }}>
                                    +91 9537853025
                                </a>
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            <i className="bi bi-envelope-paper fs-3"></i>
                            <div className='ms-3'>
                                <h6 className='fw-bold mb-1 ff-inter'>EMAIL</h6>
                                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=pradip158@gmail.com" target='blank' className='text-yellow ff-inter text-decoration-none mb-2 d-block' style={{ lineHeight: '1.2' }}>
                                    pradip158@gmail.com
                                </a>
                            </div>
                        </div>
                        <div className='d-flex mt-4'>
                            <i className="bi bi-geo-alt fs-3"></i>
                            <div className='ms-3'>
                                <h6 className='fw-bold mb-1 ff-inter'>ADDRESS</h6>
                                <a href='https://maps.google.com/?q=Shlok Shakti, Kudasan, Gandhinagar, Gujarat 382421' target='blank' className='text-decoration-none text-yellow ff-inter mb-2 d-block' style={{ lineHeight: '1.2' }}>East 415, Siddhraj z square , Opp the landmark, Podar international school road, Kudasan, Gandhinagar</a>
                            </div>
                        </div>
                    </div>
                    <h6 className='fw-bold mt-5'>Social Media Platforms</h6>
                    <div className='d-flex mt-3'>
                        <a href="https://www.facebook.com/ShlokShaktiEstate/" target='blank' className='social-icon me-4'>
                            <img height={22} width={16} src={facebook} alt="" />
                        </a>
                        <a href="https://twitter.com/PradipP36334477" target='blank' className='social-icon me-4'>
                            <i className="bi bi-twitter text-light fs-4"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/pradip-patel-rera-certified-property-consultant-b2a25a9a/" target='blank' className='social-icon'>
                            <i className="bi bi-linkedin text-light fs-4"></i>
                        </a>
                    </div>
                    <div className='mt-5'>
                        <p className='text-uppercase small text-gray mb-2' style={{ letterSpacing: '4px' }}>Connect for Expert Guidance</p>
                        <h2 className='text-black'>Call Now for Personalized Solutions</h2>
                        <p className='col-12 col-md-10'>Embark on a Seamless Real Estate Journey with Your Trusted Partner, Shlok Shakti Estate</p>
                        <Link to={'/contact-us'} className='btn-yellow btn mt-4'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsSection;
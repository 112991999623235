/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from 'react-router-dom';
import './add-property.css';
import { useState, useEffect } from 'react';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { DefaultPlayer as Video } from 'react-html5video';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Loader from '../../components/loader/loader';
import { propertyValidation } from '../../validations/validation';

const AddProperty = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const initAddPropertyForm = { title: '', subtitle: '', location: '', sellRent: '', propertyType: '', plotArea: '', constructionArea: '', bhk: 0, facing: '', price: '', furnished: '', description: '', size: '', totalFloor: 0, floor: 0, amenitiesProject: '', propertyAge: '', dimensions: '', height: '', landType: null, plotType: '', possessionDate: '', leasePeriod: '', lockPeriod: '', tenantDetails: '', preleased: false, averagePrice: '', configuration: '', projectArea: '', projectStarts: '', launchDate: '', reraNo: 0, nearByPlaces: '', aboutDeveloper: '', possessionStarts: '', totalTower: '', totalUnits: '', availableUnits: '', downloasBrochure: '' }
    const [addPropetyData, setAddPropetyData] = useState(initAddPropertyForm);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedVideos, setselectedVideos] = useState([]);
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [fileError, setFileError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!!state && !!state.propertyRequestDetails) {
            setAddPropetyData({ ...addPropetyData, sellRent: state.propertyRequestDetails.sell_rent, propertyType: state.propertyRequestDetails.property_type })
        }
    }, [])

    const handleOnChange = (e) => {
        if (e.target.name === 'title') {
            let title = e.target.value.replace(/\s+/g, ' ');
            setAddPropetyData({ ...addPropetyData, title: title })
        } else {
            setAddPropetyData({ ...addPropetyData, [e.target.name]: e.target.value })
        }
    }

    const approvePropertyRequest = async () => {
        setLoading(true);
        await axios.put(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/approvePropertyRequest/${state.id}`)
            .then(response => {
                setLoading(false)
                if (response.data.error) {
                    errorToaster(response.data.message);
                }
            })
            .catch(error => {
                setLoading(false)
                errorToaster(error);
            })
    }

    const getImagePath = (e) => {
        let seletedFile = e.target.files;
        if (seletedFile.length > 10) {
            errorToaster('Maximum images limit 10.')
        } else {
            for (let i = 0; i < seletedFile.length; i++) {
                if (seletedFile[i]) {
                    if (seletedFile[i].size < 7340032) {
                        if (seletedFile[i].type === 'image/jpg' || seletedFile[i].type === 'image/png' || seletedFile[i].type === 'image/jpeg') {
                            setSelectedFiles(selectedFiles => [...selectedFiles, seletedFile[i]])
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setSelectedImages(selectedImages => [...selectedImages, e.target.result]);
                            }
                            reader.readAsDataURL(seletedFile[i])
                        } else {
                            errorToaster('Invalid image type.')
                        }
                    } else {
                        errorToaster('File size maximum 7mb required.')
                    }
                } else {
                    return;
                }
            }
        }
    }

    const getFilePath = (e) => {
        setSelectedPdf([])
        let seletedFile = e.target.files;
        if (seletedFile[0].size < 209715200) {
            if (seletedFile[0].type === 'application/pdf') {
                setSelectedPdf(seletedFile)
            } else {
                errorToaster('Please upload pdf only.')
            }
        } else {
            errorToaster('File size maximum 200mb required.')
        }
    }

    const getVideoPath = (e) => {
        let seletedFile = e.target.files;
        if (selectedVideos.length >= 1) {
            errorToaster('Only one video required.')
        } else {
            if (seletedFile[0]) {
                if (seletedFile[0].size < 52428800) {
                    if (seletedFile[0].type === 'video/mp4' || seletedFile[0].type === 'video/x-matroska') {
                        setSelectedFiles([...selectedFiles, seletedFile[0]])
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            setselectedVideos([...selectedVideos, e.target.result]);
                        }
                        reader.readAsDataURL(seletedFile[0])
                    } else {
                        errorToaster('Invalid video type.')
                    }
                } else {
                    errorToaster('File size maximum 50mb required.')
                }
            } else {
                return;
            }
        }
    }

    const removeImage = (index) => {
        var images = [...selectedImages];
        var files = [...selectedFiles];
        images.splice(index, 1);
        files.splice(index, 1);
        setSelectedImages(images);
        setSelectedFiles(files);
    }

    const removeVideo = (index) => {
        var videos = [...selectedVideos];
        var files = [...selectedFiles];
        videos.splice(index, 1);
        files.splice(index, 1);
        setselectedVideos(videos);
        setSelectedFiles(files);
    }

    const handleChange = (e) => {
        setAddress(e);
    };

    const handleSelect = (autoCompleteAddress) => {
        setAddress(autoCompleteAddress);
        geocodeByAddress(autoCompleteAddress)
            .then(results =>
                getLatLng(results[0])
            )
            .then(latLng =>
                setAddPropetyData({ ...addPropetyData, address: autoCompleteAddress, ...latLng })
            ).catch(error => errorToaster(error))
    };

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFileError('Minimum one image required.');
        setErrors(propertyValidation({ ...addPropetyData, address }));
        if (selectedImages.length !== 0 && isEmpty(propertyValidation(addPropetyData))) {
            if (selectedImages.length > 10 && selectedVideos > 1) {
                errorToaster('Maximum 1 video and 6 images required.')
            } else {
                setLoading(true);
                const formData = new FormData();
                selectedFiles.push(...selectedPdf)
                for (let index = 0; index < selectedFiles.length; index++) {
                    const element = selectedFiles[index];
                    formData.append('allMedia', element)
                }
                setSelectedPdf([])
                formData.append('propertyData', JSON.stringify(addPropetyData))
                await axios.post(`${process.env.REACT_APP_SERVER_PROPERTY}/addProperty`, formData)
                    .then(response => {
                        setLoading(false)
                        if (response.data.error) {
                            errorToaster(response.data.message);
                        } else {
                            successToaster(response.data.message);
                            navigate('/admin/properties-admin');
                            if (state.id) {
                                approvePropertyRequest();
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                        errorToaster(error)
                    })
            }
        } else {
            return;
        }
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className='mb-3 mb-md-0'>
                    <i className="bi bi-chevron-left back-icon" role="button" onClick={() => { navigate(-1) }}></i>
                </div>
                <div className='card col-12 col-md-10 col-xl-10 mx-auto bg-white p-4 rounded-3'>

                    <h4 className='text-center py-3 mb-3'>Add Property</h4>
                    <form onSubmit={handleSubmit} method='POST'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <h6 className='label'>Title</h6>
                                <input type="text" className='input mb-4' name='title' onChange={handleOnChange} value={addPropetyData.title} placeholder='Enter title' />
                                {errors.title ?
                                    <span className='error-font'>{errors.title}</span>
                                    : ''
                                }
                            </div>
                            <div className='col-12 col-md-6'>
                                <h6 className='label'>Subtitle</h6>
                                <input type="text" className='input mb-4' name='subtitle' onChange={handleOnChange} value={addPropetyData.subtitle} placeholder='Enter subtitle' />
                                {errors.subtitle ?
                                    <span className='error-font'>{errors.subtitle}</span>
                                    : ''
                                }
                            </div>
                            <div className='col-12 col-md-6 col-lg-4'>
                                <h6 className='label'>Location</h6>
                                <input type="text" className='input mb-4' name='location' onChange={handleOnChange} value={addPropetyData.location} placeholder='Enter location' />
                                {errors.location ?
                                    <span className='error-font'>{errors.location}</span>
                                    : ''
                                }
                            </div>
                            <div className='col-12 col-md-6 col-lg-4'>
                                <h6 className='label'>Sell/Rent</h6>
                                <select className="input select-input mb-4" name='sellRent' onChange={handleOnChange} value={addPropetyData.sellRent}>
                                    <option value="">select any one type</option>
                                    <option value="sell">Sell</option>
                                    <option value="rent">Rent</option>
                                </select>
                                {errors.sellRent ?
                                    <span className='error-font'>{errors.sellRent}</span>
                                    : ''
                                }
                            </div>
                            <div className='col-12 col-md-6 col-lg-4'>
                                <h6 className='label'>Property type</h6>
                                <select className="input select-input mb-4" name='propertyType' onChange={handleOnChange} value={addPropetyData.propertyType}>
                                    <option value="">select any one type</option>
                                    <option value="bungalows">Bungalow</option>
                                    <option value="industrials">Industrial</option>
                                    <option value="shops">Shop</option>
                                    <option value="offices">Office</option>
                                    <option value="flats">Flat</option>
                                    <option value="lands">Land</option>
                                    <option value="plots">Plot</option>
                                    <option value="sheds">Shed</option>
                                    <option value="commercials">Commercial</option>
                                    <option value="residentials">Residential</option>
                                </select>
                                {errors.propertyType ?
                                    <span className='error-font'>{errors.propertyType}</span>
                                    : ''
                                }
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                <h6 className='label'>Preleased</h6>
                                <select className="input select-input" name='preleased' onChange={handleOnChange} value={addPropetyData.preleased}>
                                    <option value={true}>Preleased</option>
                                    <option value={false}>Not preleased</option>
                                </select>
                            </div>
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'sheds' || addPropetyData.propertyType === 'industrials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Plot area</h6>
                                        <input type="text" className='input' name='plotArea' onChange={handleOnChange} value={addPropetyData.plotArea} placeholder='Enter plot area' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'sheds' || addPropetyData.propertyType === 'industrials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Construction area</h6>
                                        <input type="text" className='input' name='constructionArea' onChange={handleOnChange} value={addPropetyData.constructionArea} placeholder='Enter construction area' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'flats' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>BHK</h6>
                                        <input type="number" className='input' min="1" max="5" name='bhk' onChange={handleOnChange} value={addPropetyData.bhk} placeholder='Enter bhk' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'sheds' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'industrials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Facing</h6>
                                        <input type="text" className='input' name='facing' onChange={handleOnChange} value={addPropetyData.facing} placeholder='Enter facing' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'industrials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Price</h6>
                                        <input type="text" className='input' name='price' onChange={handleOnChange} value={addPropetyData.price} placeholder='Enter price' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'bungalows' || addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'industrials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Furnished</h6>
                                        <input type="text" className='input' name='furnished' onChange={handleOnChange} value={addPropetyData.furnished} placeholder='Enter furnished' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'lands' || addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'plots' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Size</h6>
                                        <input type="text" className='input' name='size' onChange={handleOnChange} value={addPropetyData.size} placeholder='Enter size' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Total floor</h6>
                                        <input type="number" className='input' name='totalFloor' onChange={handleOnChange} value={addPropetyData.totalFloor} placeholder='Enter total floor' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'flats' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Amenities project</h6>
                                        <textarea type="text" className='input' name='amenitiesProject' onChange={handleOnChange} value={addPropetyData.amenitiesProject} placeholder='Enter amenities project' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'flats' || addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'sheds' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Property age</h6>
                                        <input type="text" className='input' name='propertyAge' onChange={handleOnChange} value={addPropetyData.propertyAge} placeholder='Enter property age' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Dimensions</h6>
                                        <input type="text" className='input' name='dimensions' onChange={handleOnChange} value={addPropetyData.dimensions} placeholder='Enter dimensions' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'offices' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Floor</h6>
                                        <input type="number" className='input' name='floor' onChange={handleOnChange} value={addPropetyData.floor} placeholder='Enter floor' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'shops' || addPropetyData.propertyType === 'offices' || addPropetyData.propertyType === 'sheds' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Height</h6>
                                        <input type="text" className='input' name='height' onChange={handleOnChange} value={addPropetyData.height} placeholder='Enter height' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'lands' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Land type</h6>
                                        <select className="input select-input" name='landType' onChange={handleOnChange} value={addPropetyData.landType ? addPropetyData.landType : ''}>
                                            <option value="">select land type</option>
                                            <option value="agriculture land">Agriculture</option>
                                            <option value="zoning">Zoning</option>
                                            <option value="tp scheme land">TP scheme land</option>
                                        </select>
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'plots' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Plot type</h6>
                                        <input type="text" className='input' name='plotType' onChange={handleOnChange} value={addPropetyData.plotType} placeholder='Enter plot type' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Possession date</h6>
                                        <input type="date" className='input' name='possessionDate' onChange={handleOnChange} value={addPropetyData.possessionDate} placeholder='Enter possession date' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Average price</h6>
                                        <input type="text" className='input' name='averagePrice' onChange={handleOnChange} value={addPropetyData.averagePrice} placeholder='Enter average price' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Configuration</h6>
                                        <input type="text" className='input' name='configuration' onChange={handleOnChange} value={addPropetyData.configuration} placeholder='Enter configuration' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Possession starts</h6>
                                        <input type="text" className='input' name='possessionStarts' onChange={handleOnChange} value={addPropetyData.possessionStarts} placeholder='Enter possession starts' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Launch date</h6>
                                        <input type="date" className='input' name='launchDate' onChange={handleOnChange} value={addPropetyData.launchDate} placeholder='Enter launch date' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Project starts</h6>
                                        <input type="text" className='input' name='projectStarts' onChange={handleOnChange} value={addPropetyData.projectStarts} placeholder='Enter project starts' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Total tower</h6>
                                        <input type="text" className='input' name='totalTower' onChange={handleOnChange} value={addPropetyData.totalTower} placeholder='Enter total tower' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Total units</h6>
                                        <input type="text" className='input' name='totalUnits' onChange={handleOnChange} value={addPropetyData.totalUnits} placeholder='Enter total units' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Rera no</h6>
                                        <input type="number" className='input' name='reraNo' onChange={handleOnChange} value={addPropetyData.reraNo} placeholder='Enter rera no' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Amenities</h6>
                                        <textarea type="text" className='input' name='amenities' onChange={handleOnChange} value={addPropetyData.amenities} placeholder='Enter amenities' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Near by places</h6>
                                        <input type="text" className='input' name='nearByPlaces' onChange={handleOnChange} value={addPropetyData.nearByPlaces} placeholder='Enter near by places' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>About developer</h6>
                                        <input type="text" className='input' name='aboutDeveloper' onChange={handleOnChange} value={addPropetyData.aboutDeveloper} placeholder='Enter about developer' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'commercials' || addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Project area</h6>
                                        <input type="text" className='input' name='projectArea' onChange={handleOnChange} value={addPropetyData.projectArea} placeholder='Enter project area' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.propertyType === 'residentials' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Available units</h6>
                                        <input type="text" className='input' name='availableUnits' onChange={handleOnChange} value={addPropetyData.availableUnits} placeholder='Enter available units' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.preleased === 'true' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Lease Period</h6>
                                        <input type="text" className='input' name='leasePeriod' onChange={handleOnChange} value={addPropetyData.leasePeriod} placeholder='Enter Lease Period' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.preleased === 'true' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>lockPeriod</h6>
                                        <input type="text" className='input' name='lockPeriod' onChange={handleOnChange} value={addPropetyData.lockPeriod} placeholder='Enter Lock Period' />
                                    </div> : ''
                            }
                            {
                                addPropetyData.preleased === 'true' ?
                                    <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                        <h6 className='label'>Tenant Details</h6>
                                        <input type="text" className='input' name='tenantDetails' onChange={handleOnChange} value={addPropetyData.tenantDetails} placeholder='Enter Tenant Details' />
                                    </div> : ''
                            }
                            <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                <h6 className='label'>Downloas brochure ({selectedFiles.length})</h6>
                                <input type="file" name="downloasBrochure" accept="application/pdf" onChange={getFilePath} className='input' />
                            </div>
                        </div>
                        <div className='row'>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className='col-12 col-md-6'>
                                        <h6 className='label'>Address</h6>
                                        <div className='mb-4 position-relative'>
                                            <textarea name='address' rows={3} {...getInputProps({ placeholder: 'Enter address', className: 'input' })} />
                                            <div className="map-search-autocomplete">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion, index) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                            key={index}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        {errors.address ?
                                            <span className='error-font'>{errors.address}</span>
                                            : ''
                                        }
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <div className='col-12 col-md-6'>
                                <h6 className='label'>Description</h6>
                                <textarea className='input mb-4' rows={3} name='description' onChange={handleOnChange} value={addPropetyData.description} placeholder='Enter description' />
                                {errors.description ?
                                    <span className='error-font'>{errors.description}</span>
                                    : ''
                                }
                            </div>
                        </div>
                        <div className='d-flex flex-wrap mt-2'>
                            <div className='mb-4'>
                                <label htmlFor='image_url' className='image-box d-flex align-items-center justify-content-center' role='button'>
                                    <i className="bi bi-card-image"></i>
                                    <input type="file" multiple name="imageUrl" accept="image/png, image/jpeg, image/jpg" onChange={getImagePath} id="image_url" className='d-none' />
                                </label>
                            </div>
                            <div className='mb-4'>
                                <label htmlFor='video_url' className='image-box d-flex align-items-center justify-content-center' role="button">
                                    <i className="bi bi-camera-video"></i>
                                    <input type="file" name="videoUrl" accept="video/mp4, video/x-matroska" onChange={getVideoPath} id="video_url" className='d-none' />
                                </label>
                            </div>
                            {
                                selectedImages.length ?
                                    selectedImages.map((imagePath, index) => (
                                        <div key={index} className='position-relative'>
                                            <img src={imagePath} className='image-box mb-3' alt="" />
                                            <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeImage(index) }}></i>
                                        </div>
                                    )) : ""
                            }
                            {
                                selectedVideos.length ?
                                    selectedVideos.map((videoPath, index) => (
                                        <div key={index} className='position-relative'>
                                            <Video className="image-box mb-3" controls={[]} muted>
                                                <source className='h-100 w-100' src={videoPath} />
                                            </Video>
                                            <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeVideo(index) }}></i>
                                        </div>
                                    )) : ""
                            }
                        </div>
                        {fileError && selectedImages.length < 1 ?
                            <span className='error-font'>{fileError}</span>
                            : ''
                        }
                        <div className='col-12 text-end'>
                            <button type='button' className='btn btn-danger me-3' onClick={() => { navigate(-1) }}>Cancel</button>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddProperty
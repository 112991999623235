/* eslint-disable no-loop-func */
import { useState } from 'react';
import axios from 'axios';
import './sell-property.css';
import { sellPropertyValidations } from '../../validations/validation';
import { DefaultPlayer as Video } from 'react-html5video';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const SellProperty = () => {

    const initSellPropertyForm = { name: '', phone: '', sellRent: '', propertyType: '', address: '', propertyDetails: '' };
    const [errors, setErrors] = useState({});
    const [category, setCategory] = useState();
    const [propertyType, setPropertyType] = useState();
    const [sellPropertyData, setSellPropertyData] = useState(initSellPropertyForm);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedVideos, setselectedVideos] = useState([]);
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);

    const chooseCategory = (type) => {
        setCategory(type)
        setSellPropertyData({ ...sellPropertyData, 'sellRent': type })
    }

    const choosePropertyType = (type) => {
        setPropertyType(type)
        setSellPropertyData({ ...sellPropertyData, 'propertyType': type })
    }

    const handleOnchange = (e) => {
        setSellPropertyData({ ...sellPropertyData, [e.target.name]: e.target.value })
    }

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const getImagePath = (e) => {
        let seletedFile = e.target.files;
        if (selectedFiles.length > 10) {
            errorToaster('Maximum images limit 10.')
        } else {
            for (let i = 0; i < seletedFile.length; i++) {
                if (seletedFile[i]) {
                    if (seletedFile[i].size < 7340032) {
                        if (seletedFile[i].type === 'image/jpg' || seletedFile[i].type === 'image/png' || seletedFile[i].type === 'image/jpeg') {
                            setSelectedFiles(selectedFiles => [...selectedFiles, seletedFile[i]])
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setSelectedImages(selectedImages => [...selectedImages, e.target.result]);
                            }
                            reader.readAsDataURL(seletedFile[i])
                        } else {
                            errorToaster('Invalid image type.')
                        }
                    } else {
                        errorToaster('File size maximum 7mb required.')
                    }
                } else {
                    return;
                }
            }
        }
    }

    const getVideoPath = (e) => {
        let seletedFile = e.target.files;
        if (selectedVideos.length >= 1) {
            errorToaster('Only one video required.')
        } else {
            for (let i = 0; i < seletedFile.length; i++) {
                if (seletedFile[i]) {
                    if (seletedFile[i].size < 26214400) {
                        if (seletedFile[i].type === 'video/mp4' || seletedFile[i].type === 'video/x-matroska') {
                            setSelectedFiles([...selectedFiles, seletedFile[i]])
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setselectedVideos([...selectedVideos, e.target.result]);
                            }
                            reader.readAsDataURL(seletedFile[i])
                        } else {
                            errorToaster('Invalid video type.')
                        }
                    } else {
                        errorToaster('File size maximum 25mb required.')
                    }
                } else {
                    return;
                }
            }
        }
    }

    const removeImage = (index) => {
        var images = [...selectedImages];
        var files = [...selectedFiles];
        images.splice(index, 1);
        files.splice(index, 1);
        setSelectedImages(images);
        setSelectedFiles(files);
    }

    const removeVideo = (index) => {
        var videos = [...selectedVideos];
        var files = [...selectedFiles];
        videos.splice(index, 1);
        files.splice(index, 1);
        setselectedVideos(videos);
        setSelectedFiles(files);
    }

    const handleChange = (e) => {
        setAddress(e);
    };

    const handleSelect = (autoCompleteAddress) => {
        setAddress(autoCompleteAddress);
        setSellPropertyData({ ...sellPropertyData, address: autoCompleteAddress })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedFiles.length > 11) {
            errorToaster('Maximum 1 video and 10 images required.');
        } else {
            setErrors(sellPropertyValidations(sellPropertyData))
            if (isEmpty(sellPropertyValidations(sellPropertyData))) {
                const formData = new FormData();
                for (let index = 0; index < selectedFiles.length; index++) {
                    const element = selectedFiles[index];
                    formData.append('allMedia', element)
                }
                formData.append('propertyData', JSON.stringify(sellPropertyData))
                setLoading(true);
                await axios.post(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/addPropertyRequest`, formData)
                    .then(response => {
                        setLoading(false);
                        if (response.data.error) {
                            errorToaster(response.data.message)
                        } else {
                            successToaster(response.data.message)
                            setSellPropertyData(initSellPropertyForm)
                            setSelectedImages([]);
                            setselectedVideos([]);
                            setSelectedFiles([]);
                            setCategory('')
                            setPropertyType('')
                            setAddress('')
                        }
                    }).catch(error => {
                        setLoading(false);
                        errorToaster(error)
                    })
            } else {
                return;
            }
        }
    }

    return (
        <div className='mt-66'>
            {loading ? <Loader /> : ''}
            <Helmet>
                <title>Shlok Shakti - Sell/Rent property</title>
                <meta name='description' content='Shlok shakrti sell/rent property page' />
            </Helmet>

            {/* Title */}
            <div className='title-bg position-relative mb-4'>
                <div className='title text-center container-lg'>
                    <h2 className='text-white fs-56 fs-30'>Sell/Rent Your Property</h2>
                    <div className='black-des mx-auto my-2'></div>
                    <h4 className='text-white'>Shlok Shakti</h4>
                </div>
            </div>

            {/* Sell/Rent Form */}
            <div className='mt-5 container-lg'>
                <div className="text-center">
                    <h5 className='text-yellow mb-3'>Sell or Rent with Ease</h5>
                    <h1 className='text-black fw-bolder mb-4'>Share Your Property Details Today!</h1>
                </div>
                <form onSubmit={handleSubmit} method='POST' className='row mb-5 pb-4'>
                    <div className='col-12 col-md-6'>
                        <label htmlFor="name" className="label">Name</label>
                        <input type="text" id='name' name='name' className="input mb-4" onChange={handleOnchange} value={sellPropertyData.name} placeholder='Name' />
                        {errors.name ?
                            <span className='error-font'>{errors.name}</span> : ''
                        }
                    </div>
                    <div className='col-12 col-md-6'>
                        <label htmlFor="phoneNumber" className="label">Phone Number</label>
                        <input type="number" id='phoneNumber' name='phone' onChange={handleOnchange} value={sellPropertyData.phone} className="input mb-4" placeholder='Phone number' />
                        {errors.phone ?
                            <span className='error-font'>{errors.phone}</span> : ''
                        }
                    </div>
                    <div className='col-12 col-md-6'>
                        <label className="label mb-3">I’m looking to</label>
                        <div>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-4 ' + (category === 'rent' ? 'active-cat' : '')} onClick={() => { chooseCategory('rent') }}>Rent</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-4 ' + (category === 'sell' ? 'active-cat' : '')} onClick={() => { chooseCategory('sell') }}>Sell</button>
                            {errors.sellRent ?
                                <span className='error-font'>{errors.sellRent}</span> : ''
                            }
                        </div>
                        <label className="label mb-3">What kind of property do you have ?</label>
                        <div className='mb-2'>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'bungalows' ? 'active-cat' : '')} onClick={() => { choosePropertyType('bungalows') }}>House</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'industrials' ? 'active-cat' : '')} onClick={() => { choosePropertyType('industrials') }}>Industrial</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'flats' ? 'active-cat' : '')} onClick={() => { choosePropertyType('flats') }}>Flat</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'shops' ? 'active-cat' : '')} onClick={() => { choosePropertyType('shops') }}>Shop</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'offices' ? 'active-cat' : '')} onClick={() => { choosePropertyType('offices') }}>Offices</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'lands' ? 'active-cat' : '')} onClick={() => { choosePropertyType('lands') }}>Land</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'plots' ? 'active-cat' : '')} onClick={() => { choosePropertyType('plots') }}>Plot</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'sheds' ? 'active-cat' : '')} onClick={() => { choosePropertyType('sheds') }}>Sheds</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'commercials' ? 'active-cat' : '')} onClick={() => { choosePropertyType('commercials') }}>Commercial</button>
                            <button type='button' className={'btn btn-outline-gray me-3 mb-3 ' + (propertyType === 'residentials' ? 'active-cat' : '')} onClick={() => { choosePropertyType('residentials') }}>Residential</button>
                        </div>
                        {errors.propertyType ?
                            <span className='error-font'>{errors.propertyType}</span> : ''
                        }
                    </div>
                    <div className='d-none d-md-block col-6'>
                        <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <div className='mb-4'>
                                        <label htmlFor="address-lg" className="label">Address</label>
                                        <textarea rows={3} id='address-lg' {...getInputProps({ placeholder: 'Enter address', className: 'input' })} />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                        key={index}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {errors.address ?
                                        <span className='error-font'>{errors.address}</span> : ''
                                    }
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className='d-flex flex-wrap mt-2'>
                        <div className='mb-3'>
                            <label htmlFor='image_url' className='image-box d-flex align-items-center justify-content-center' role='button'>
                                <i className="bi bi-card-image"></i>
                                <input type="file" multiple name="imageUrl" accept="image/png, image/jpeg, image/jpg" onChange={getImagePath} id="image_url" className='d-none' />
                            </label>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='video_url' className='image-box d-flex align-items-center justify-content-center' role="button">
                                <i className="bi bi-camera-video"></i>
                                <input type="file" name="videoUrl" accept="video/mp4, video/x-matroska" onChange={getVideoPath} id="video_url" className='d-none' />
                            </label>
                        </div>
                        {
                            selectedImages.length ?
                                selectedImages.map((imagePath, index) => (
                                    <div key={index} className='position-relative'>
                                        <img src={imagePath} className='image-box border-0 mb-3' alt="" />
                                        <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeImage(index) }}></i>
                                    </div>
                                )) : ""
                        }
                        {
                            selectedVideos.length ?
                                selectedVideos.map((videoPath, index) => (
                                    <div key={index} className='position-relative'>
                                        <Video className="image-box mb-3" controls={[]} muted>
                                            <source className='h-100 w-100' src={videoPath} />
                                        </Video>
                                        <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeVideo(index) }}></i>
                                    </div>
                                )) : ""
                        }
                    </div>
                    <div className='d-md-none d-block col-12'>
                        <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className='mb-4'>
                                    <label htmlFor="address" className="label">Address</label>
                                    <textarea rows={3} id='address' {...getInputProps({ placeholder: 'Enter address', className: 'input' })} />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion, index) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                    key={index}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {errors.address ?
                                        <span className='error-font'>{errors.address}</span> : ''
                                    }
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div className='col-12'>
                        <label htmlFor="propertyDetails" className="label">Property Details</label>
                        <textarea rows={5} id='propertyDetails' name='propertyDetails' onChange={handleOnchange} value={sellPropertyData.propertyDetails} className="input mb-4" placeholder='Property Details'></textarea>
                        {errors.propertyDetails ?
                            <span className='error-font'>{errors.propertyDetails}</span> : ''
                        }
                    </div>
                    <div className='mt-4'>
                        <button type='submit' className='btn btn-yellow'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SellProperty;

import React from "react";
import { NavLink } from "react-router-dom";

import logo from '../../../assets/Images/logo-bw.png';
import "./sidebar.css";

const sidebar = () => {
    return (
        <div className="sidebar-content">
            <NavLink to="/admin" className="sidebar-header p-1 d-flex align-items-center text-decoration-none">
                <img src={logo} alt="" height={35} width={45} />
                <div>
                    <h3 className='brand-footer'>shlok shakti</h3>
                    <h5 className='brand-sm-footer m-0'>estate</h5>
                </div>
            </NavLink>
            <NavLink to="dashboard" className="sidebar-item">
                <i className="bi bi-speedometer2 me-3"></i>
                Dashboard
            </NavLink>
            <NavLink to="users" className="sidebar-item">
                <i className="bi bi-people me-3"></i>
                Users
            </NavLink>
            <NavLink to="properties-admin" className="sidebar-item">
                <i className="bi bi-buildings me-3"></i>
                Properties
            </NavLink>
            <NavLink to="property-requests" className="sidebar-item">
                <i className="bi bi-bag me-3"></i>
                Property Requests
            </NavLink>
            <NavLink to="contact-admin" className="sidebar-item">
                <i className="bi bi-envelope me-3"></i>
                Contact
            </NavLink>
            <NavLink to="feedback" className="sidebar-item">
                <i className="bi bi-chat-right-heart me-3"></i>
                Feedback
            </NavLink>
            <NavLink to="slider-setting" className="sidebar-item">
                <i className="bi bi-gear me-3"></i>
                Slider setting
            </NavLink>
        </div>
    );
};

export default sidebar;

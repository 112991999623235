/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import './property-request.css';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import Loader from '../../components/loader/loader';

const PropertyRequest = () => {

    const gridRef = useRef();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [loading, setLoading] = useState(true);
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        cellClass: 'no-border'
    }));

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/getAllPropertyRequests`)
            .then(result => {
                setLoading(false);
                setRowData(result.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            });

        setColumnDefs([
            { headerName: 'Name', field: 'name', maxWidth: 200, minWidth: 120 },
            { headerName: 'Phone no', field: 'phone', minWidth: 130, },
            { headerName: 'Sell/Rent', field: 'sell_rent', minWidth: 160, },
            { headerName: 'Property type', field: 'property_type', minWidth: 160, },
            { headerName: 'Status', field: 'status', minWidth: 100, maxWidth: 150 },
        ])
    }

    const navigateTo = useCallback(event => {
        navigate(`/admin/view-property-request/${event.data._id}`)
    }, [])

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className="ag-theme-alpine p-3" style={{ width: '100%', height: 'calc(100vh - 68px)' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    paginationAutoPageSize={true}
                    pagination={true}
                    animateRows={true}
                    rowSelection='multiple'
                    onRowClicked={navigateTo}
                />
            </div>
        </div >
    );
}

export default PropertyRequest;
import React, { useState } from 'react';
import { userLoginValidation } from '../../validations/validation';
import { useNavigate } from 'react-router-dom';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const Login = () => {

    const initLoginForm = { email: '', password: '' };
    const [loginData, setData] = useState(initLoginForm);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const routerNavigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleHideShowPass = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        setData({ ...loginData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        setErrors(userLoginValidation(loginData));
        e.preventDefault();
        if (loginData.email === '' || loginData.password === '') {
            return
        } else {
            await axios.post(`${process.env.REACT_APP_SERVER_ADMIN}/adminLogin`, loginData)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster("Login successfully.")
                        localStorage.setItem('apiKey', JSON.stringify(response.data.token))
                        routerNavigate('/admin');
                    }
                }).catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        }
    }

    return (
        <div style={{ backgroundColor: "#212333", backgroundImage: "linear-gradient(180deg, #3c4b64 0%, #212333 100%)" }}>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <Helmet>
                <title>Shlok Shakti - Admin login</title>
                <meta name='description' content='Shlok shakrti admin login' />
            </Helmet>
            <div className="container vh-100 d-flex align-items-center py-3">
                <div className="col-12 col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto p-0">
                    <div className="card p-4 rounded-2">
                        <h2 className="my-5 text-dark-blue fw-bold text-center">Sign in</h2>
                        <form onSubmit={handleSubmit} method='POST'>
                            <div>
                                <label htmlFor="email" className="label">Email</label>
                                <input type='text' placeholder='Email' id='email' onChange={handleChange} value={loginData.email} name='email' className={'col-12 input mb-4 ' + (errors.email ? 'error-input' : '')} />
                                {errors.email ?
                                    <span className='error-font'>{errors.email}</span> : ''
                                }
                            </div>
                            <div className='input-box'>
                                <label htmlFor="password" className="label">Password</label>
                                <input type={showPassword ? 'text' : 'password'} placeholder='Password' id='password' onChange={handleChange} value={loginData.password} name='password' className={'col-12 input mb-4 ' + (errors.password ? 'error-input' : '')} />
                                {
                                    showPassword ?
                                        <i className="bi bi-eye-fill show-hide-icon" onClick={handleHideShowPass} role='button'></i> :
                                        <i className="bi bi-eye-slash-fill show-hide-icon" onClick={handleHideShowPass} role='button'></i>
                                }
                            </div>
                            {errors.password ?
                                <span className='error-font'>{errors.password}</span> : ''
                            }
                            <div className="w-100 py-3">
                                <button type='submit' className='mb-2 col-12 btn btn-yellow'>Sign in</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import './search-property.css';
import { useNavigate } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const SearchProperty = (props) => {

  const categories = [{ category: 'bungalows' }, { category: 'industrials' }, { category: 'flats' }, { category: 'shops' }, { category: 'offices' }, { category: 'lands' }, { category: 'plots' }, { category: 'sheds' }, { category: 'commercials' }, { category: 'residentials' }];
  const [activeCategory, setActiveCategory] = useState('');
  const [address, setAddress] = useState('');
  const [buyRent, setBuyRent] = useState('');
  const [landType, setLandType] = useState('');
  const [bhk, setBhk] = useState(0);
  const [preleased, setPreleased] = useState('');
  const [allCoordinates, setAllCoordinates] = useState()
  const navigate = useNavigate();


  useEffect(() => {
    if (props) {
      if (props.selectedTab) {
        setActiveCategory(props.selectedTab);
      }
      if (props.getPropertyType) {
        props.getPropertyType(props.selectedTab);
      }
      if (props.address) {
        setAddress(props.address);
      }
      if (props.buyRent) {
        setBuyRent(props.buyRent);
      }
      if (props.landType) {
        setLandType(props.landType);
      }
      if (props.bhk) {
        setBhk(props.bhk);
      }
      if (props.preleased) {
        setPreleased(props.preleased);
      }
    }
  }, [])

  const handleCategoryChange = (e) => {
    setLandType('');
    setBhk(0);
    setActiveCategory(e.target.value);
    if (!!props && !!props.getPropertyType) {
      props.getPropertyType(e.target.value);
    }
  }

  const handleLandTypeChange = (e) => {
    setLandType(e.target.value)
    if (!!props && !!props.selectedLandType) {
      props.selectedLandType(e.target.value);
    }
  }

  const handleBhk = (e) => {
    setBhk(Number(e.target.value))
    if (!!props && !!props.selectedBhk) {
      props.selectedBhk(e.target.value);
    }
  }

  const handleChangeSellRent = (e) => {
    setBuyRent(e.target.value)
    if (!!props && !!props.selectedBuyRent) {
      props.selectedBuyRent(e.target.value);
    }
  }

  const handleChangePreleased = (e) => {
    setPreleased(e.target.value)
    if (!!props && !!props.selectedPreleased) {
      props.selectedPreleased(e.target.value);
    }
  }

  const handleSelectAddress = (autoCompleteAddress) => {
    setAddress(autoCompleteAddress);
    if (!!props && !!props.getSearchedKeyWord) {
      props.getSearchedKeyWord(autoCompleteAddress)
    }
    geocodeByAddress(autoCompleteAddress)
      .then(results =>
        getLatLng(results[0])
      ).then(latLng => {
        setAllCoordinates(latLng)
        if (!!props && !!props.getlatLngBySearch) {
          props.getlatLngBySearch(latLng)
        }
      }).catch(error => {
        if (!!props && !!props.getlatLngBySearch) {
          props.getlatLngBySearch('')
        }
        console.log(error);
      })
  }

  const handleSearch = () => {
    if (props.title === 'home') {
      navigate('/properties', { state: { selectedCategory: activeCategory, latLng: allCoordinates, address: address, buyRent: buyRent, bhk: bhk, landType: landType, preleased: preleased } })
    }
  }

  return (
    <div>
      <div className='search-property position-relative'>
        <div className={'d-flex align-items-center px-4 py-4 ' + (props.title === 'mapView' ? 'container-lg' : '')}>
          {props.showBackBtn === true ?
            <i className="bi bi-chevron-left me-3 back-icon" role='button' onClick={() => navigate(-1)}></i> : ''
          }
          <div className='d-none d-md-block border-right me-2'>
            <select className="property-select border-0 me-2" name='buyRent' onChange={handleChangeSellRent} value={buyRent}>
              <option value=''>Buy/Rent</option>
              <option value='sell'>Buy</option>
              <option value='rent'>Rent</option>
            </select>
          </div>
          <div className='d-flex align-items-center w-100'>
            <i className="bi bi-geo-alt-fill text-yellow"></i>
            <PlacesAutocomplete
              value={address}
              onChange={(e) => { setAddress(e) }}
              onSelect={handleSelectAddress}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='w-100 me-4'>
                  <input {...getInputProps({ placeholder: 'Search for locality, landmark, project or builder', className: 'search-input w-100 ms-2' })} />
                  <div className="map-search-autocomplete">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={index}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className='d-none d-md-block border-left'>
            <select className="property-select border-0 mx-2" name='preleased' onChange={handleChangePreleased} value={preleased}>
              <option value=''>Preleased properties</option>
              <option value='true'>Preleased</option>
              <option value='false'>Not Preleased</option>
            </select>
          </div>
          {
            activeCategory === 'lands' ?
              <div className='d-none d-md-block border-left'>
                <select className="property-select border-0 mx-2" name='landType' onChange={handleLandTypeChange} value={landType}>
                  <option value="">Land Type</option>
                  <option value="agriculture land">Agriculture</option>
                  <option value="zoning">Zoning</option>
                  <option value="tp scheme land">TP scheme land</option>
                </select>
              </div> : ''
          }
          {
            activeCategory === 'flats' || activeCategory === 'bungalows' ?
              <div className='d-none d-md-block border-left'>
                <select className="property-select border-0 mx-2" name='bhk' onChange={handleBhk} value={bhk}>
                  <option value={0}>BHKs</option>
                  <option value={1}>1 bhk</option>
                  <option value={2}>2 bhk</option>
                  <option value={3}>3 bhk</option>
                  <option value={4}>4 bhk</option>
                  <option value={5}>5 bhk</option>
                </select>
              </div> : ''
          }
          <div className='border-left d-none d-md-block'>
            <select className="property-select border-0 ms-2" name='propertyType' onChange={handleCategoryChange} value={activeCategory}>
              <option value="">Property Type</option>
              {categories.map((data, index) => (
                <option key={index} value={data.category}>{data.category}</option>
              ))}
            </select>
          </div>
          <div className="dropdown d-md-none d-block">
            <button type="button" className='d-block d-md-none btn-yellow btn search-btn' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="bi bi-sliders"></i>
            </button>
            <div className="dropdown-menu">
              <select className="dropdown-item border-0 py-2 mobile-select-menu" name='propertyType' onChange={handleCategoryChange} value={activeCategory}>
                <option value="">Property Type</option>
                {categories.map((data, index) => (
                  <option key={index} value={data.category}>{data.category}</option>
                ))}
              </select>
              {
                activeCategory === 'flats' || activeCategory === 'bungalows' ?
                  <select className="dropdown-item border-0 py-2 mobile-select-menu" name='bhk' onChange={handleBhk} value={bhk}>
                    <option value="0">BHKs</option>
                    <option value="1">1 bhk</option>
                    <option value="2">2 bhk</option>
                    <option value="3">3 bhk</option>
                    <option value="4">4 bhk</option>
                    <option value="5">5 bhk</option>
                  </select> : ''
              }
              {
                activeCategory === 'lands' ?
                  <select className="dropdown-item border-0 py-2 mobile-select-menu" name='landType' onChange={handleLandTypeChange} value={landType}>
                    <option value="">Land Type</option>
                    <option value="agriculture land">Agriculture</option>
                    <option value="zoning">Zoning</option>
                    <option value="tp scheme land">TP scheme land</option>
                  </select> : ''
              }
              <select className="dropdown-item border-0 py-2 mobile-select-menu" name='buyRent' onChange={handleChangeSellRent} value={buyRent}>
                <option value=''>Buy/Rent</option>
                <option value='sell'>Buy</option>
                <option value='rent'>Rent</option>
              </select>
              <select className="dropdown-item border-0 py-2 mobile-select-menu" name='preleased' onChange={handleChangePreleased} value={preleased}>
                <option value=''>Preleased properties</option>
                <option value={true}>Preleased</option>
                <option value={false}>Not Preleased</option>
              </select>
            </div>
          </div>
          {
            props.title === 'home' ?
              <button className='btn-yellow btn search-btn ms-2' onClick={handleSearch}>
                <i className='bi bi-search'></i>
              </button>
              : ''
          }
        </div>
      </div>
    </div>
  );
}

export default SearchProperty;
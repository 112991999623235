/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation } from 'react-router-dom';
import './giftcity-property.css';
import SearchProperty from '../../components/search-property/search-property';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const GiftcityProperty = () => {

    const { state } = useLocation();
    const [propertyData, setFilteredProperty] = useState([])
    const [allProperties, setAllProperties] = useState()
    const [selectedPropertyType, setSelectedPropertyType] = useState('')
    const [buyRent, setBuyRent] = useState('')
    const [searchedCoordinates, setSearchedCoordinates] = useState()
    const [landType, setLandType] = useState('');
    const [bhk, setBhk] = useState(0);
    const [preleased, setPreleased] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPropertyData();
    }, [])

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then((res) => {
                setLoading(false);
                setAllProperties(
                    res.data.filter((e) => {
                        let location = e.location.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
                        return location.includes('giftcity')
                    })
                )
                setFilteredProperty(
                    res.data.filter((e) => {
                        let location = e.location.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
                        return location.includes('giftcity')
                    })
                )
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const changePropertyType = (propertyCategory) => {
        setLandType('');
        setBhk(0);
        setSelectedPropertyType(propertyCategory);

        const setFilteredPropertyByCategory = (propertyCategory) => {
            setFilteredProperty(allProperties?.filter(res => {
                const distance = searchedCoordinates ? getDistance(searchedCoordinates.lat, searchedCoordinates.lng, res.lat, res.lng) : 0;

                const isMatchingCategory = propertyCategory === '' || res.property_type === propertyCategory;

                return (
                    (!searchedCoordinates || distance < 3.5) &&
                    (!buyRent || res.sell_rent === buyRent) &&
                    (!preleased || res.preleased?.toString() === preleased) &&
                    isMatchingCategory
                );
            }));
        };

        setFilteredPropertyByCategory(propertyCategory);
    }

    const searchedLatlng = (searchedLatlng) => {
        setSearchedCoordinates(searchedLatlng)

        const filterProperties = (property, distanceCheck) => {
            const distance = distanceCheck ? getDistance(searchedLatlng.lat, searchedLatlng.lng, property.lat, property.lng) : 0;
            return (
                (!distanceCheck || distance < 3.5) &&
                (!bhk || property.bhk === bhk) &&
                (!landType || property.land_type === landType) &&
                (!buyRent || property.sell_rent === buyRent) &&
                (!selectedPropertyType || property.property_type === selectedPropertyType) &&
                (!preleased || property.preleased?.toString() === preleased)
            );
        };

        const filteredProperties = allProperties?.filter(property => {
            return searchedLatlng === '' ? filterProperties(property, false) : filterProperties(property, true);
        });

        setFilteredProperty(filteredProperties);
    }

    const changeBuyRentType = (buyRentType) => {
        setBuyRent(buyRentType);

        const filterCondition = (res, searchedCoordinates, distanceCheck) => {
            const distance = searchedCoordinates ? getDistance(searchedCoordinates.lat, searchedCoordinates.lng, res.lat, res.lng) : 0;
            return (
                (!searchedCoordinates || distanceCheck(distance)) &&
                (!bhk || res.bhk === bhk) &&
                (!landType || res.land_type === landType) &&
                (!selectedPropertyType || res.property_type === selectedPropertyType) &&
                (!preleased || res.preleased?.toString() === preleased)
            );
        };

        const distanceCheck = distance => distance < 3.5;

        const filteredProperties = allProperties?.filter(res => {
            const isBuyRentTypeEmpty = buyRentType === '';
            const condition = filterCondition(res, searchedCoordinates, distanceCheck);

            return isBuyRentTypeEmpty ? condition : (res.sell_rent === buyRentType && condition);
        });

        setFilteredProperty(filteredProperties);
    }

    const changeBhk = (bhk) => {
        setBhk(Number(bhk))

        const setFilteredProperties = (bhkFilter) => {
            setFilteredProperty(allProperties?.filter(res => {
                const distance = searchedCoordinates ? getDistance(searchedCoordinates.lat, searchedCoordinates.lng, res.lat, res.lng) : 0;

                const commonConditions = (
                    (!preleased || res.preleased?.toString() === preleased) &&
                    (!buyRent || res.sell_rent === buyRent) &&
                    (!selectedPropertyType || res.property_type === selectedPropertyType) &&
                    (!searchedCoordinates || distance < 3.5)
                );

                return bhkFilter(res, distance) && commonConditions;
            }));
        };

        if (Number(bhk) === 0) {
            setFilteredProperties(res => true);
        } else {
            setFilteredProperties(res => res.bhk === Number(bhk));
        }
    }

    const changeLandType = (landType) => {
        setLandType(landType)

        const setFilteredProperties = (landFilter) => {
            setFilteredProperty(allProperties?.filter(res => {
                const distance = searchedCoordinates ? getDistance(searchedCoordinates.lat, searchedCoordinates.lng, res.lat, res.lng) : 0;

                const commonConditions = (
                    (!preleased || res.preleased?.toString() === preleased) &&
                    (!buyRent || res.sell_rent === buyRent) &&
                    (!selectedPropertyType || res.property_type === selectedPropertyType) &&
                    (!searchedCoordinates || distance < 3.5)
                );

                return landFilter(res, distance) && commonConditions;
            }));
        };

        if (landType === '') {
            setFilteredProperties(res => true);
        } else {
            setFilteredProperties(res => res.land_type === landType);
        }
    }

    const changePreleased = (preleased) => {
        setPreleased(preleased)

        const filterCondition = (res, searchedCoordinates, distanceCheck) => {
            const distance = searchedCoordinates ? getDistance(searchedCoordinates.lat, searchedCoordinates.lng, res.lat, res.lng) : 0;
            return (
                (!searchedCoordinates || distanceCheck(distance)) &&
                (!bhk || res.bhk === bhk) &&
                (!landType || res.land_type === landType) &&
                (!buyRent || res.sell_rent === buyRent) &&
                (!selectedPropertyType || res.property_type === selectedPropertyType) &&
                (!preleased || res.preleased?.toString() === preleased)
            );
        };

        const distanceCheck = distance => distance < 3.5;

        const filteredProperties = allProperties?.filter(res => {
            return preleased === '' ? filterCondition(res, searchedCoordinates, distanceCheck) : filterCondition(res, searchedCoordinates, distanceCheck);
        });

        setFilteredProperty(filteredProperties);
    }

    const getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = degToRad(lat2 - lat1);
        const dLon = degToRad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c;

        return distance;
    }

    const degToRad = (deg) => {
        return deg * (Math.PI / 180);
    }

    return (
        <div className='mt-66'>
            {loading ? <Loader /> : ''}

            <Helmet>
                <title>Shlok Shakti - Prperties</title>
                <meta name='description' content='Shlok shakrti properties page' />
            </Helmet>

            <div className='container-lg d-flex align-items-center justify-content-center pt-5'>
                <h1 className='text-yellow'>Gift City Properties</h1>
            </div>
            {/* Search property */}
            <div className="container-lg search-box mb-5">
                <SearchProperty showBackBtn={false} getPropertyType={changePropertyType} getlatLngBySearch={searchedLatlng} selectedBuyRent={changeBuyRentType} selectedBhk={changeBhk} selectedLandType={changeLandType} selectedPreleased={changePreleased} address={!!state && state.address} buyRent={!!state && state.buyRent} bhk={!!state && state.bhk} landType={!!state && state.landType} preleased={!!state && !!state.preleased} />
            </div>

            <div className='container-lg'>
                <div className='row mb-5 h-100'>
                    {
                        propertyData?.length ?
                            propertyData?.map((propertyData, index) => (
                                <div key={index} className='col-12 col-md-6 col-lg-4 col-xxl-3 h-100 p-3 p-md-3'>
                                    <Link to={'/property-details/' + propertyData.title.replace(/\s+/g, '-')} className='card rounded-4 h-100 text-decoration-none'>
                                        <img src={propertyData.filePath} className='rounded-top-4 image-height' alt="" />
                                        <div className="card-body p-4">
                                            <div className='d-flex align-items-center mb-3'>
                                                <i className="bi bi-tags-fill text-yellow me-2"></i>
                                                <h6 className='text-gray m-0 fs-14'>{propertyData.property_type}</h6>
                                            </div>
                                            <h5 className='mb-2 prop-title'>{propertyData.title}</h5>
                                            <div className='border-top-gray d-flex align-items-center text-lightgray pt-2'>
                                                <i className="bi bi-geo-alt me-2"></i>
                                                <h6 className='m-0 fw-normal'>{propertyData.location}</h6>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mt-3'>
                                                <span className='text-yellow'>Know more...</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )) :
                            <div className='d-flex align-items-center justify-content-center my-5'>
                                <h5>No results found</h5>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default GiftcityProperty;

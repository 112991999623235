import './App.css';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import mamLogo from '../../../assets/Images/map-logo.jpg';
import ScrollToTop from '../../../components/scroll-to-top/scroll-to-top';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

const App = () => {

  const [isOpenPopup, setIsOpenPopup] = useState(true);
  const navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>Shlok Shakti - Home</title>
        <meta name='description' content='Shlok shakrti home page' />
      </Helmet>
      <div className='header'>
        <Header />
      </div>
      <div className='position-relative'>
        <ScrollToTop>
          <Outlet />
          <Popover
            isOpen={isOpenPopup}
            positions={['top', 'bottom', 'left', 'right']}
            content={<div className='mapview-popover'>
              <i className="bi bi-x d-flex justify-content-end" role='button' onClick={() => { setIsOpenPopup(false) }}></i>
              <h5 className='text-yellow me-2'>Explore our new feature</h5>
              <Link to='/map-view' className='text-decoration-none d-flex'>View properties in Map<i className="bi bi-reply-fill explore-icon"></i></Link>
            </div>}
          >
            <div className='map-logo'>
              <img src={mamLogo} height={64} onMouseOver={() => { setIsOpenPopup(true) }} onClick={() => navigate("/map-view")} role='button' className='rounded-circle' alt="" />
            </div>
          </Popover>
          <Footer />
        </ScrollToTop>
      </div>
    </div>
  );
}

export default App;

import './about.css';

import value from '../../assets/Images/value.png';
import vision from '../../assets/Images/vision.png';
import mission from '../../assets/Images/mission.png';
import experiance from '../../assets/Images/Mask group.png';
import properties from '../../assets/Images/Mask group-1.png';
import clients from '../../assets/Images/Mask group-2.png';
import aboutUsRight from '../../assets/Images/about-right.jpg';


import ContactUsSection from '../../components/contact-us-section/contact-us-section';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const About = () => {

    return (
        <div className='mt-66'>
            <Helmet>
            <meta name="title" content="About - Shlok Shakti" />
            <meta name="description" content="At Shlok Shakti Estate, our story is woven with a commitment to excellence in Gandhinagar and Ahmedabad real estate. Founded by esteemed Realtor..." />
            <meta name="keywords" content="Shlok Shakti, Gandhinagar, Ahmedabad, Real estate," />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <link rel="canonical" href={window.location.href} />
            <meta property="og:title" content="About - Shlok Shakti" />
            <meta property="og:type" content="Reale state" />
            <meta property="og:description" content="At Shlok Shakti Estate, our story is woven with a commitment to excellence in Gandhinagar and Ahmedabad real estate. Founded by esteemed Realtor..." />
            <meta property="og:url" content="http://sse.flitzinteractive.com/about" />
            <meta property="og:image" content="http://sse.flitzinteractive.com/static/media/about-right.af8733cde0309421c2c1.jpg" />
            </Helmet>
            {/* Title */}
            <div className='heading-bg position-relative mb-4'>
                <div className='title text-center container-lg'>
                    <h2 className='text-white fs-56 fs-30'>The story of Shlok Shakti</h2>
                    <div className='black-des mx-auto my-2'></div>
                    <h4 className='text-white'>About us</h4>
                </div>
            </div>

            {/* Video and Info */}
            <div className='container-lg'>
                <div className='row align-items-center pt-5 pb-md-5 flex-row-reverse'>
                    <div className='col-12 col-md-6'>
                        <div className='ms-auto col-12 col-md-11 col-lg-11'>
                            <img src={aboutUsRight} alt="" className='h-100 w-100 rounded-4' />
                        </div>
                    </div>
                    <div className='col-12 col-md-6 text-center text-md-start mt-4 mt-md-0'>
                        <h1 className='mb-3'>About Shlok Shakti</h1>
                        <p className='col-12 col-md-11 col-lg-11 fs-5'>
                            At Shlok Shakti Estate, our story is woven with a commitment to excellence in Gandhinagar and Ahmedabad real estate. Founded by esteemed Realtor, Mr. Pradip Patel, we embark on the journey of turning dreams into tangible reality. Our unwavering commitment is reflected in our diverse portfolio, which offers a spectrum of real estate solutions.
                        </p>
                        <p className='col-12 col-md-11 col-lg-11 fs-5'>
                            With a focus on trust, transparency and unmatched local expertise, Shlok Shakti Estate is the epitome of real estate reliability. We don't just deal in real estate; we gather experience and facilitate seamless transactions for our clients. Join us as you navigate the dynamic landscape of Gandhinagar and Ahmedabad where your real estate aspirations will find a home.
                        </p>
                    </div>
                </div>
            </div>
            <div className='container-lg'>
                <div className='row align-items-center py-5'>
                    <div className='col-12 col-md-6'>
                        <div className='video-box col-12 col-md-11 col-lg-11'>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 text-center text-md-start mt-4 mt-md-0'>
                        <div className='ms-auto col-12 col-md-11 col-lg-11'>
                            <h3 className='mb-3'>Pradip Patel's been at it for a decade.</h3>
                            <p className='fs-5'>
                                Meet the visionary force behind Shlok Shakti Estate, Mr. Pradip Patel. With a wealth of confidence and experience, Mr. Pradip Patel stands as the cornerstone of our commitment to excellence in Gandhinagar and Ahmedabad real estate. His expertise goes beyond the conventional and makes him a trusted guide in the dynamic world of real estate transactions.
                            </p>
                            <p className='fs-5'>
                                Driven by a passion for providing personalized solutions, Mr. Pradip Patel ensures that every client's journey with Shlok Shakti Estate is marked by professionalism and integrity. With a keen understanding of the local market, he continues to redefine service standards in the real estate industry. Connect with Mr. Patel for a real estate experience that goes beyond transactions and creates lasting property relationships.
                            </p>
                            <Link to={'/contact-us'} className='btn btn-outline-yellow px-4 py-2 text-yellow'>Contact Now</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Plans */}
            <div className='container-lg'>
                <div className='row align-items-center py-5'>
                    <div className='col-12 col-sm-8 col-md-4 mx-auto mb-4 mb-md-0 px-md-2 px-lg-3'>
                        <div className='card col-12 text-center rounded-3 px-4 px-md-2 px-lg-4 py-5'>
                            <div className='icon-box mx-auto mb-3'>
                                <img src={value} height={32} alt="" />
                            </div>
                            <h3 className='text-black'>Our Values</h3>
                            <p className='text-lightgray'>Shlok Shakti Real Estate is founded on unwavering integrity, ensuring ethical conduct in all dealings. Our commitment to innovation drives creative solutions, and dedication to excellence ensures exceptional service. We deeply value our community, actively contributing to its development.</p>
                        </div>
                    </div>
                    <div className='col-12 col-sm-8 col-md-4 mx-auto mb-4 mb-md-0 px-md-2 px-lg-3'>
                        <div className='card col-12 text-center rounded-3 px-4 px-md-2 px-lg-4 py-5'>
                            <div className='icon-box mx-auto mb-3'>
                                <img src={vision} height={32} alt="" />
                            </div>
                            <h3 className='text-black'>Our Vision</h3>
                            <p className='text-lightgray'>To be the premier real estate partner in Gandhinagar and Ahmedabad, recognized for unwavering commitment to integrity, innovation, and excellence. Envisioning a future where every individual's real estate aspirations are realized through expert guidance and personalized solutions.</p>
                        </div>
                    </div>
                    <div className='col-12 col-sm-8 col-md-4 mx-auto mb-4 mb-md-0 px-md-2 px-lg-3'>
                        <div className='card col-12 text-center rounded-3 px-4 px-md-2 px-lg-4 py-5'>
                            <div className='icon-box mx-auto mb-3'>
                                <img src={mission} height={32} alt="" />
                            </div>
                            <h3 className='text-black'>Our Mission</h3>
                            <p className='text-lightgray'>At Shlok Shakti Real Estate, we empower individuals and businesses in Gandhinagar and Ahmedabad with comprehensive real estate solutions. Committed to seamless transactions, trust, and transparency, we redefine excellence, turning your aspirations into reality with unwavering dedication and personalized service.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Counts */}
            <div className='bg-yellow py-5 my-3'>
                <div className='container-lg'>
                    <div className='row'>
                        <div className='col-12 col-md-4 text-center text-white'>
                            <img src={experiance} height={38} alt="" />
                            <h2 className='my-3'>+15</h2>
                            <small>Year Of Experience</small>
                        </div>
                        <div className='col-12 col-md-4 mt-5 pt-4 pt-md-0 mt-md-0 text-center text-white'>
                            <img src={properties} height={38} alt="" />
                            <h2 className='my-3'>+2000</h2>
                            <small>Properties</small>
                        </div>
                        <div className='col-12 col-md-4 mt-5 pt-4 pt-md-0 mt-md-0 text-center text-white'>
                            <img src={clients} height={38} alt="" />
                            <h2 className='my-3'>+3500</h2>
                            <small>Clients</small>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Us */}
            <ContactUsSection title="Any Query?" subTitle="We are here for free Consultation "></ContactUsSection>
        </div>
    );
}

export default About;

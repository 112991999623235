import { useEffect, useState } from 'react';
import './slider-image-setting.css';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import { DefaultPlayer as Video } from 'react-html5video';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Whirligig from 'react-whirligig';
import Loader from '../../components/loader/loader';

const SliderImageSetting = () => {

    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedVideos, setselectedVideos] = useState([]);
    const [getOldImages, setOldImages] = useState();
    const [getOldVideos, setOldVideos] = useState();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSliderImages()
        getSliderVideos()
    }, [])

    const getSliderImages = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_SETTING}/getSliderImages`)
            .then(res => {
                setLoading(false);
                setOldImages(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getSliderVideos = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_SETTING}/getSliderVideos`)
            .then(res => {
                setLoading(false);
                setOldVideos(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getImagePath = (e) => {
        let seletedFile = e.target.files;
        for (let i = 0; i < seletedFile.length; i++) {
            if (seletedFile[i]) {
                if (seletedFile[i].size < 7340032) {
                    if (seletedFile[i].type === 'image/jpg' || seletedFile[i].type === 'image/png' || seletedFile[i].type === 'image/jpeg') {
                        setSelectedFiles(selectedFiles => [...selectedFiles, seletedFile[i]])
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            setSelectedImages(selectedImages => [...selectedImages, e.target.result]);
                        }
                        reader.readAsDataURL(seletedFile[i])
                    } else {
                        errorToaster('Invalid image type.')
                    }
                } else {
                    errorToaster('File size maximum 7mb required.')
                }
            } else {
                return;
            }
        }
    }

    const getVideoPath = (e) => {
        let seletedFile = e.target.files;
        for (let i = 0; i < seletedFile.length; i++) {
            if (seletedFile[i]) {
                if (seletedFile[i].size < 52428800) {
                    if (seletedFile[i].type === 'video/mp4' || seletedFile[i].type === 'video/x-matroska') {
                        setSelectedFiles(selectedFiles => [...selectedFiles, seletedFile[i]])
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            setselectedVideos(selectedVideos => [...selectedVideos, e.target.result]);
                        }
                        reader.readAsDataURL(seletedFile[i])
                    } else {
                        errorToaster('Invalid video type.')
                    }
                } else {
                    errorToaster('File size maximum 50mb required.')
                }
            } else {
                return;
            }
        }
    }

    const removeImage = (index) => {
        var images = [...selectedImages];
        var files = [...selectedFiles];
        images.splice(index, 1);
        files.splice(index, 1);
        setSelectedImages(images);
        setSelectedFiles(files);
    }

    const removeVideo = (index) => {
        var videos = [...selectedVideos];
        var files = [...selectedFiles];
        videos.splice(index, 1);
        files.splice(index, 1);
        setselectedVideos(videos);
        setSelectedFiles(files);
    }

    const handleCancel = () => {
        setSelectedFiles([])
        setSelectedImages([])
        setselectedVideos([])
    }

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        for (let index = 0; index < selectedFiles.length; index++) {
            const element = selectedFiles[index];
            formData.append('sliderImges', element)
        }
        await axios.post(`${process.env.REACT_APP_SERVER_SETTING}/addSliderImage`, formData)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    successToaster(response.data.message)
                    handleCancel()
                    getSliderImages()
                    getSliderVideos()
                }
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const deleteFile = async (id, fileType) => {
        setLoading(true);
        if (fileType === 'image') {
            await axios.delete(`${process.env.REACT_APP_SERVER_SETTING}/deleteImageFile/${id}`)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster(response.data.message)
                        getSliderImages();
                        getSliderVideos();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        } else {
            await axios.delete(`${process.env.REACT_APP_SERVER_SETTING}/deleteVideoFile/${id}`)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster(response.data.message)
                        getSliderVideos();
                        getSliderImages();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        }
    }

    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();

    const handleCloseModal = () => setShow(false);
    const handleShowImage = () => setShow(true);

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className='d-flex flex-wrap'>
                    <div className='mb-3'>
                        <label htmlFor='image_url' className='image-box d-flex align-items-center justify-content-center' role='button'>
                            <i className="bi bi-card-image"></i>
                            <input type="file" multiple name="imageUrl" accept="image/png, image/jpeg, image/jpg" onChange={getImagePath} id="image_url" className='d-none' />
                        </label>
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='video_url' className='image-box d-flex align-items-center justify-content-center' role="button">
                            <i className="bi bi-camera-video"></i>
                            <input type="file" multiple name="videoUrl" accept="video/mp4, video/x-matroska" onChange={getVideoPath} id="video_url" className='d-none' />
                        </label>
                    </div>
                    {
                        selectedImages.length ?
                            selectedImages.map((imagePath, index) => (
                                <div key={index} className='position-relative'>
                                    <img src={imagePath} className='image-box border-0 mb-3' alt="" />
                                    <i className="bi bi-x-circle-fill text-danger close-btn" style={{ zIndex: '1000' }} onClick={() => { removeImage(index) }}></i>
                                </div>
                            )) : ""
                    }
                    {
                        selectedVideos.length ?
                            selectedVideos.map((videoPath, index) => (
                                <div key={index} className='position-relative'>
                                    <Video className="image-box mb-3" controls={[]} muted>
                                        <source className='h-100 w-100' src={videoPath} />
                                    </Video>
                                    <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeVideo(index) }}></i>
                                </div>
                            )) : ""
                    }
                </div>
                {
                    selectedFiles.length ?
                        <div>
                            <button className='btn btn-danger me-3' onClick={handleCancel}>Cancel</button>
                            <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                        </div> : ''
                }
                <h5 className='my-4'>Slider images & videos</h5>
                <div className='d-flex flex-wrap mt-2'>
                    {
                        getOldImages?.length ?
                            getOldImages.map((data, index) => (
                                <div key={index} className='position-relative' onClick={handleShowImage} role='button' >
                                    <img src={data.filePath} className='image-box border border-secondary mb-3' alt="" />
                                    <i className="bi bi-x-circle-fill text-danger close-btn " onClick={() => { deleteFile(data.id, 'image') }}></i>
                                </div>
                            )) : ''
                    }
                    {
                        getOldVideos?.length ?
                            getOldVideos.map((data, index) => (
                                <div key={index} className='position-relative' onClick={handleShowImage} role='button' >
                                    <Video className="image-box border border-secondary" controls={[]} muted>
                                        <source className='h-100 w-100' src={data.filePath} />
                                    </Video>
                                    <i className="bi bi-x-circle-fill text-danger close-btn " onClick={() => { deleteFile(data.id, 'video') }}></i>
                                </div>
                            )) : ''
                    }
                </div>
                <Modal show={show} onHide={handleCloseModal} className='view-images-modal' centered>
                    <Whirligig
                        visibleSlides={1}
                        ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
                        preventScroll='false'
                        className="rounded slider-box"
                        style={{ maxHeight: 'calc(100vh - 100px)' }}
                    >
                        {
                            getOldImages?.map((res, index) => (
                                <img key={index} className='h-100 w-100' src={res?.filePath} alt="" style={{ objectFit: 'cover', objectPosition: 'center' }} />
                            ))
                        }
                        {
                            getOldVideos?.map((res, index) => (
                                <Video key={index} className="h-100 w-100" autoPlay loop controls={[]} muted>
                                    <source src={res.filePath} type='video/mp4' />
                                </Video>
                            ))
                        }
                    </Whirligig>
                    <i onClick={prev} className='indicator-l bi bi-caret-left fs-3' role='button'></i>
                    <i onClick={next} className='indicator-r bi bi-caret-right fs-3' role='button'></i>
                </Modal>
            </div>
        </div>
    )
}

export default SliderImageSetting;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import './dashboard.css';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import { AgGridReact } from 'ag-grid-react';
import Loader from '../../components/loader/loader';

const Dashboard = () => {

    const [allPropertyData, setAllPropertyData] = useState();
    const [availableProperties, setAvailableProperties] = useState();
    const [soldProperties, setSoldProperties] = useState();
    const [propertyRequests, sePropertyRequests] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [recentPropertyRequests, setRecentPropertyRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    const gridRef = useRef();
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        cellClass: 'no-border'
    }));

    useEffect(() => {
        getPropertyData();
        getPropertyRequstData();
    }, [])

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    setAllPropertyData(response.data);
                    setAvailableProperties(response.data?.filter(e => { return e.status === 'Available' }));
                    setSoldProperties(response.data?.filter(e => { return e.status === 'Sold' }));
                }
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyRequstData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTYREQUEST}/getAllPropertyRequests`)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    sePropertyRequests(response.data)
                    setRecentPropertyRequests(response.data.slice(Math.max(response.data.length - 5, 0)))
                    setColumnDefs([
                        { headerName: 'Name', field: 'name', maxWidth: 200, minWidth: 120 },
                        { headerName: 'Phone no', field: 'phone', minWidth: 130, },
                        { headerName: 'Sell/Rent', field: 'sell_rent', minWidth: 160, },
                        { headerName: 'Property type', field: 'property_type', minWidth: 160, },
                        { headerName: 'Status', field: 'status', minWidth: 100, maxWidth: 150 },
                    ])
                }
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className='row mb-3'>
                    <div className='col-12 col-md-6 col-xl-3 mb-4'>
                        <div className='px-3 py-4 h-100 rounded-2 text-light d-flex align-items-center' style={{ background: '#3c4b64' }}>
                            <div className='count-circel'>
                                <h3 className='m-0' style={{ color: '#3c4b64' }}>{allPropertyData?.length}</h3>
                            </div>
                            <h5 className='ms-3 m-0'>Total properties</h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 mb-4'>
                        <div className='px-3 py-4 h-100 rounded-2 text-light d-flex align-items-center' style={{ background: '#0d6efd' }}>
                            <div className='count-circel'>
                                <h3 className='m-0' style={{ color: '#0d6efd' }}>{availableProperties?.length}</h3>
                            </div>
                            <h5 className='ms-3 m-0'>Available properties</h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 mb-4'>
                        <div className='px-3 py-4 h-100 rounded-2 text-light d-flex align-items-center' style={{ background: '#f0a019' }}>
                            <div className='count-circel'>
                                <h3 className='m-0' style={{ color: '#f0a019' }}>{soldProperties?.length}</h3>
                            </div>
                            <h5 className='ms-3 m-0'>Sold properties</h5>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 mb-4'>
                        <div className='px-3 py-4 h-100 rounded-2 text-light d-flex align-items-center' style={{ background: '#1eba34' }}>
                            <div className='count-circel'>
                                <h3 className='m-0' style={{ color: '#1eba34' }}>{propertyRequests?.length}</h3>
                            </div>
                            <h5 className='ms-3 m-0'>Total property requests</h5>
                        </div>
                    </div>
                </div>
                <div className="d-flex py-3 px-3 admin-title align-items-center">
                    <h4 className="pl-3 mb-0 pb-0">Recent property requests</h4>
                </div>
                <div className="ag-theme-alpine" style={{ width: '100%', height: '261px' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={recentPropertyRequests}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import './App.css';
import Sidebar from '../sidebar/sidebar';
import Header from '../header/header';
import { Helmet } from 'react-helmet-async';


const Admin = () => {
    const [isOpen, setIsClose] = useState(true);
    const [userData, setUserData] = useState({});
    const toggleSidebar = (data) => {
        setIsClose(data);
    };

    useEffect(() => {
        var token = JSON.parse(localStorage.getItem('apiKey'))
        const decoded = jwt_decode(token);
        setUserData(decoded.response)
    }, [])


    return (
        <div className='d-flex vh-100'>

            <Helmet>
                <title>Shlok Shakti - Admin</title>
                <meta name='description' content='Shlok Shakti Real Estate - Admin' />
            </Helmet>

            <div className={(isOpen ? 'sidebar' : 'sidebar-sm')}>
                <Sidebar />
            </div>
            <div className='w-100'>
                <div className='nav-bar'>
                    <Header toggle={toggleSidebar} userData={userData} />
                </div>
                <div className='w-100 content overflow-auto position-relative'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Admin;

/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './home.css';
import Whirligig from 'react-whirligig';

import SearchProperty from '../../components/search-property/search-property';
import ContactUsSection from '../../components/contact-us-section/contact-us-section';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import flatImage from '../../assets/Images/flat.jpg';
import houseImage from '../../assets/Images/house.jpg';
import landImage from '../../assets/Images/land.jpg';
import commercialImage from '../../assets/Images/commercial.jpg';
import residentialImage from '../../assets/Images/residential.jpg';
import propertyImage from '../../assets/Images/propertyImage.jpg';
import propertyImage2 from '../../assets/Images/propertyImage2.jpg';
import shopImage from '../../assets/Images/shop.jpg';
import aboutUs from '../../assets/Images/about-right.jpg';
import preleasedImage from '../../assets/Images/preleased.jpg';
import plotImage from '../../assets/Images/plot.png';
import officeImage from '../../assets/Images/office.jpeg';
import industrialImage from '../../assets/Images/industrial.png';
import giftCityImage from '../../assets/Images/gift-city.png';
import imgPlacehodler from '../../assets/Images/img-placeholder.png';
import { useState } from 'react';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import Loader from '../../components/loader/loader';

const Home = () => {

    const navigate = useNavigate();
    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();
    const newPropertyEle = useRef(null);
    const feedbackEle = useRef(null);
    const [feedbackData, setFeedbackData] = useState();
    const [allProperties, setAllProperties] = useState([]);
    const [sliderImages, setSliderImages] = useState();
    const [sliderVideos, setSliderVideos] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFeedbackData();
        getPropertyData();
        getSliderImages();
        getSliderVideos();
    }, [])

    const slideLeft = (data) => {
        data === 'newProperty' ?
            newPropertyEle.current.scrollLeft -= 350 :
            feedbackEle.current.scrollLeft -= 350;
    }

    const slideRight = (data) => {
        data === 'newProperty' ?
            newPropertyEle.current.scrollLeft += 350 :
            feedbackEle.current.scrollLeft += 350;
    }

    const handleNavigateTo = (title) => {
        navigate('/properties', { state: { selectedCategory: title } })
    }

    const getFeedbackData = async () => {
        setLoading(true);
        await axios.get(`${process.env.REACT_APP_SERVER_FEEDBACK}/getAllFeedbackData`)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    setFeedbackData(response.data)
                }
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then((res) => {
                setLoading(false);
                setAllProperties(res.data.slice(0, 10))
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getSliderImages = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_SETTING}/getSliderImages`)
            .then(res => {
                setLoading(false);
                setSliderImages(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getSliderVideos = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_SETTING}/getSliderVideos`)
            .then(res => {
                setLoading(false);
                setSliderVideos(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const handlePreleased = () => {
        navigate('/properties', { state: { preleased: 'true' } })
    }

    return (
        <div>
            {loading ? <Loader /> : ''}
            <div className='mt-66'>
                <div className={'position-relative container-lg mb-5 ' + (loading === true ? '' : 'mt-3')}>
                    <div className='row align-items-center pb-5'>

                        <div className='quote-box col-12 col-md-6 col-lg-6 col-xl-6'>
                            <div className='quote-bg'>
                                <div className='d-flex align-items-center'>
                                    <h1 className='quote-title'>Discover, Dream</h1>
                                    <div className='bg-yellow des'></div>
                                </div>
                                <h1 className='quote-title'>and Invest with</h1>
                                <h1 className='quote-title'>Shlok Shakti Estate</h1>
                                <p className='text-gray quote-subtitle fw-normal mt-3 mb-0'>Shlok Shakti Estate: Your trusted guide in Gandhinagar and  Ahmedabad real estate. Discover, buy, sell, or rent with confidence.</p>
                            </div>
                        </div>

                        {/* slider */}
                        <div className='position-relative col-12 col-md-6'>
                            <Whirligig
                                visibleSlides={1}
                                ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
                                preventScroll='false'
                                className="rounded-4 border-yellow overflow-hidden align-items-center"
                            >
                                {/* <Video className="h-100 w-100" autoPlay loop controls={[]} muted>
                                    <source src={defaultSliderVideo} type='video/mp4' />
                                </Video> */}
                                {/* {
                                    sliderVideos.length || sliderImages.length &&
                                } */}

                                {
                                    sliderVideos?.length > 0 || sliderImages?.length > 0 ?
                                        [
                                            sliderVideos?.map(files => (
                                                <Video key={files.id} className="" autoPlay loop controls={[]} muted>
                                                    <source src={files.filePath} type='video/mp4' />
                                                </Video>
                                            )),
                                            sliderImages?.map(files => (
                                                <img key={files.id} src={files.filePath} alt="" className='h-100 w-100 object-fit-cover' />
                                            ))
                                        ] :
                                        <div style={{ height: '350px', width: "100%" }} className='d-flex align-items-center justify-content-center'>
                                            <img src={imgPlacehodler} alt="" height={100} width={100} className='object-fit-contain' />
                                        </div>
                                }


                            </Whirligig>
                            <div className='indicators'>
                                <button onClick={prev} className='left-indicator'><i className="bi bi-arrow-left"></i></button>
                                <button onClick={next} className='right-indicator'><i className="bi bi-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Search property */}
                <div className="container-lg mb-5" >
                    <SearchProperty showBackBtn={false} title='home' />
                </div >

                {/* Categories */}
                <div className='container-lg' >
                    <div className='row py-4 mb-5'>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('flats') }}>
                                <img src={flatImage} className="card-img-top" alt="flat_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Flats</h4>
                                    <p className="card-text">Elevate your living experience with our diverse range of modern and comfortable flats. Find the perfect space to call home.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('bungalows') }}>
                                <img src={houseImage} className="card-img-top" alt="house_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Bungalows</h4>
                                    <p className="card-text">Experience luxury and spacious living in our exclusive bungalows. Your dream home awaits in the lap of comfort and style.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3 h-100'>
                            <div className="card h-100" role='button' onClick={() => { handleNavigateTo('lands') }}>
                                <img src={landImage} className="card-img-top" alt="land_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Lands</h4>
                                    <p className="card-text">Invest in the future with our prime land offerings. Unlock the potential for growth and development in strategic locations.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('commercials') }}>
                                <img src={commercialImage} className="card-img-top" alt="commercial_image" />
                                <div className="card-body">
                                    <h4 className="card-title">New Commercial Project</h4>
                                    <p className="card-text">Propel your business to new heights with our state-of-the-art commercial projects. Where innovation meets opportunity.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('residentials') }}>
                                <img src={residentialImage} className="card-img-top" alt="residential_image" />
                                <div className="card-body">
                                    <h4 className="card-title">New Recedential Project</h4>
                                    <p className="card-text">Embrace a new lifestyle in our innovative residential projects. Modern amenities and thoughtful design redefine your living space.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('shops') }}>
                                <img src={shopImage} className="card-img-top" alt="shop_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Shops</h4>
                                    <p className="card-text">Discover prime retail spaces tailored to your business needs. Unlock the perfect location for success and visibility.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('plots') }}>
                                <img src={plotImage} className="card-img-top" alt="plot_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Plots</h4>
                                    <p className="card-text">Premium plots for investment or dream homes in high-potential locales. Secure your tomorrow, today.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('offices') }}>
                                <img src={officeImage} className="card-img-top" alt="office_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Offices</h4>
                                    <p className="card-text">Dynamic office spaces in prime business hubs designed for corporate success and networking.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { navigate('/giftcity-property') }}>
                                <img src={giftCityImage} className="card-img-top" alt="giftcity_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Gift City</h4>
                                    <p className="card-text">Forward-thinking financial tech spaces in Gift City, tailored for industry innovators and leaders.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4 p-3'>
                            <div className="card" role='button' onClick={() => { handleNavigateTo('industrials') }}>
                                <img src={industrialImage} className="card-img-top" alt="industrial_image" />
                                <div className="card-body">
                                    <h4 className="card-title">Industrials</h4>
                                    <p className="card-text">Strategically placed industrial sites enhancing manufacturing efficiency and growth potential.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                {/* Preleased */}
                <div className='container-lg mb-5' >
                    <h2 className='text-center text-black mb-3'>Preleased Properties</h2>
                    <div className='card'>
                        <div className='row pb-5 pt-4 align-items-center'>
                            <div className='col-12 col-md-8 col-lg-6 mx-auto'>
                                <img className='col-12 rounded-3' src={preleasedImage} alt="" />
                            </div>
                            <div className='col-12 col-md-8 col-lg-6 mx-auto text-center text-lg-start'>
                                <h4 className='me-3 text-black'>Why Pre-Leased Property Investment is the Best</h4>
                                <p className='col-12 col-md-11 mt-4 mx-sm-auto mx-lg-0 me-3'>
                                    Investing in pre-leased properties offers immediate returns with existing tenants, eliminating the need for tenant searches. This low-risk option allows for thorough tenant profile and lease evaluation, ensuring a stable income stream from the start.
                                </p>
                                <p className='col-12 col-md-11 my-4 mx-sm-auto mx-lg-0 me-3'>
                                    Furthermore, pre-leased properties provide superior returns with reduced investment risk, requiring minimal management effort. With prime locations and consistent rental income, they offer an attractive choice for portfolio diversification and long-term financial growth.
                                </p>
                                <button className='btn btn-yellow mt-3 text-capitalize' onClick={handlePreleased}>see more</button>
                            </div>
                        </div>
                    </div>
                </div >

                {/* New Property */}
                < div className='new-property mb-5' >
                    <div className='container-lg'>
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-4 col-xl-3 text-center text-md-start'>
                                <h2 className='text-black'>New Property</h2>
                                <p className='col-12 col-sm-9 mx-auto mx-md-0'>Explore the latest additions to our portfolio, offering a diverse range of properties. From residential gems to lucrative commercial spaces, find your ideal investment with Shlok Shakti.</p>
                                <Link to={'/properties'} className='btn-yellow btn'>See More</Link>
                            </div>
                            <div className='col-12 col-md-8 col-lg-8 col-xl-9 mt-5 mt-md-0'>
                                <div className='col-12 row text-center m-0 scroll-smooth flex-nowrap overflow-auto' ref={newPropertyEle}>
                                    {
                                        allProperties?.map(property => (
                                            <div key={property._id} className='col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                                                <Link to={'/property-details/' + property.title.replace(/\s+/g, '-')} className='text-decoration-none text-black'>
                                                    {
                                                        property.filePath ?
                                                            <img className='col-11 rounded-4 h-200 object-fit-cover' src={property.filePath} alt="" /> :
                                                            <div className='col-11 rounded-4 h-200 bg-white d-flex align-items-center justify-content-center mx-auto'>
                                                                <h6 className='m-0'>No Image</h6>
                                                            </div>
                                                    }
                                                    <h6 className='fw-bold mt-3 ff-inter new-props-title'>{property.title}</h6>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                    {
                                        allProperties.length <= 0 ?
                                            <div className='col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                                                <img className='col-11 rounded-4 h-200' src={propertyImage} alt="" />
                                                <h6 className='fw-bold mt-3 ff-inter new-props-title'>Quality Residence</h6>
                                            </div> : ''
                                    }
                                    {
                                        allProperties.length <= 0 ?
                                            <div className='col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                                                <img className='col-11 rounded-4 h-200' src={propertyImage2} alt="" />
                                                <h6 className='fw-bold mt-3 ff-inter new-props-title'>Top notch Facilities</h6>
                                            </div> : ''
                                    }
                                    {
                                        allProperties.length <= 0 ?
                                            <div className='col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                                                <img className='col-11 rounded-4 h-200' src={propertyImage} alt="" />
                                                <h6 className='fw-bold mt-3 ff-inter new-props-title'>Quality Residence</h6>
                                            </div> : ''
                                    }
                                </div>
                                <div className='text-center text-md-end me-md-4 mt-4'>
                                    <button className='left-indicator bg-white' onClick={() => { slideLeft('newProperty') }}><i className="bi bi-arrow-left"></i></button>
                                    <button className='right-indicator' onClick={() => { slideRight('newProperty') }}><i className="bi bi-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* About Us */}
                <div className='container-lg mb-5' >
                    <div className='row pb-5 pt-4 align-items-center flex-row-reverse'>
                        <div className='col-12 col-md-8 col-lg-6 mx-auto'>
                            <div className='d-flex align-items-center justify-content-center mb-4 col-12 d-lg-none'>
                                <div className='yellow-line col-3 col-sm-4 col-lg-2'></div>
                                <h3 className='mx-3 mx-sm-4 text-black'>About Us</h3>
                                <div className='yellow-line col-3 col-sm-4 col-lg-6'></div>
                            </div>
                            <img className='col-12 rounded-3' src={aboutUs} alt="" />
                        </div>
                        <div className='col-12 col-md-8 col-lg-6 mx-auto text-center text-lg-start'>
                            <div className='d-lg-flex align-items-center d-none'>
                                <h3 className='me-3 text-black'>About Us</h3>
                                <div className='yellow-line' style={{ width: '65%' }}></div>
                            </div>
                            <p className='col-12 col-md-11 mt-4 mx-sm-auto mx-lg-0'>
                                Welcome to Shlok Shakti Estate, your gateway to the best properties in Gandhinagar and beyond. Founded by the seasoned property broker, Mr. Pradip Patel, our mission is to redefine your real estate experience. Whether you're in search of the best properties in Gandhinagar or seeking prime real estate opportunities, we specialize in providing tailored solutions that go beyond expectations.
                            </p>
                            <p className='col-12 col-md-11 my-4 mx-sm-auto mx-lg-0'>
                                Navigating the dynamic landscape of Gandhinagar property, we pride ourselves on being the trusted choice for those in pursuit of excellence. From flats to commercial projects, we bring you the finest options, ensuring your real estate journey is synonymous with quality, trust, and unmatched local expertise. Choose Shlok Shakti Estate for a personalized and rewarding real estate experience in Gandhinagar and its vibrant surroundings.
                            </p>
                            <Link to={'/about'} className='btn btn-outline-yellow mt-3'>Know More <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div >

                {/* Feedbacks */}
                {
                    feedbackData?.length ?
                        < div className='text-center container-lg pb-5' >
                            <span className='text-yellow fs-5'>Testimonials</span>
                            <h2 className='my-2 text-black'>What Our Satisfied Clients Speak</h2>
                            <h6 className='text-darkgray text-capitalize'>Real Stories, Real Success with Shlok Shakti Estate.</h6>
                            <div className='row flex-nowrap overflow-auto py-5 scroll-smooth' ref={feedbackEle}>
                                {
                                    feedbackData?.map((res, index) => (
                                        <div key={index} className='card col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 mx-4 p-4'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <img src={res.image_path} alt="" className='rounded-circle object-fit-contain' style={{ height: '75px', width: '75px', border: "1px solid #d6d6d6" }} />
                                                <div className='text-start ms-3'>
                                                    <h6 className='text-capitalize mb-1 fw-bold'>{res.name}</h6>
                                                    <div className='d-flex'>
                                                        {
                                                            [...Array(res.rating)].map((star, index) => {
                                                                return (
                                                                    <i key={index} className="bi bi-star-fill me-1 text-yellow"></i>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='text-start text-overflow-elisps'>{res.feedback}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                <button className='left-indicator' onClick={() => { slideLeft('feedback') }}><i className="bi bi-arrow-left"></i></button>
                                <button className='right-indicator' onClick={() => { slideRight('feedback') }}><i className="bi bi-arrow-right"></i></button>
                            </div>
                        </div > : ''
                }

                {/* Contact Us */}
                < ContactUsSection title="Contact us" subTitle="Get in Touch" ></ContactUsSection >
            </div>
        </div>
    );
}

export default Home;

const charactarsPattern = /^[A-Za-z]+$/;
const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const propertyTitlePattern = /^[^\s]+(\s+[^\s]+)*$/;
const noSpacialChar = /^[A-Za-z0-9 ]+$/;

export const userRegisterValidations = (inputValues) => {
    const errors = {};

    // Name validations
    if (inputValues.name === "") {
        errors.name = "Name is required."
    } else if (inputValues.name.length < 2) {
        errors.name = "Name is more than 2 charactars."
    } else if (inputValues.name.length >= 50) {
        errors.name = "Name is not more than 50 charactars."
    }

    // Email validations
    if (inputValues.email === "") {
        errors.email = "Email is required."
    } else if (!inputValues.email.match(emailPattern)) {
        errors.email = "Please enter a valid Email."
    }

    // Password validations
    if (inputValues.password === "") {
        errors.password = "Password is required."
    } else if (inputValues.password.length < 8) {
        errors.password = "Password is more than 8 charactars."
    } else if (inputValues.password.length >= 16) {
        errors.password = "Password is not more than 16 charactars."
    } else if (!inputValues.password.match(passwordPattern)) {
        errors.password = "Please enter a strong password."
    }

    // Confirm password validations
    if (inputValues.confirmPassword === "") {
        errors.confirmPassword = "Confirm Password is required."
    } else if (inputValues.password !== inputValues.confirmPassword) {
        errors.confirmPassword = "Password did'nt match."
    }

    return errors;

};

export const userLoginValidation = (inputValues) => {
    const errors = {};

    // Email validations
    if (inputValues.email === "") {
        errors.email = "Email is required."
    }

    // Password validations
    if (inputValues.password === "") {
        errors.password = "Password is required."
    }

    return errors;
}

export const contactUsValidation = (inputValues) => {
    const errors = {};

    // First name validations
    if (inputValues.firstName === "") {
        errors.firstName = "First name is required."
    } else if (inputValues.firstName.length < 2) {
        errors.firstName = "First name is more than 2 charactars."
    } else if (inputValues.firstName.length >= 50) {
        errors.firstName = "First name is not more than 50 charactars."
    } else if (!inputValues.firstName.match(charactarsPattern)) {
        errors.firstName = "Please enter a valid name."
    }

    // Last name validations
    if (inputValues.lastName === "") {
        errors.lastName = "Last name is required."
    } else if (inputValues.lastName.length < 2) {
        errors.lastName = "Last name is more than 2 charactars."
    } else if (inputValues.lastName.length >= 50) {
        errors.lastName = "Last name is not more than 50 charactars."
    } else if (!inputValues.lastName.match(charactarsPattern)) {
        errors.lastName = "Please enter a valid name."
    }

    // Email validations
    if (inputValues.email === "") {
        errors.email = "Email is required."
    } else if (!inputValues.email.match(emailPattern)) {
        errors.email = "Please enter a valid Email."
    }

    // Phone number validations
    if (inputValues.phone === "") {
        errors.phone = "Phone number is required."
    } else if (!inputValues.phone.match(phonePattern)) {
        errors.phone = "Please enter a valid Phone number."
    }

    return errors;
}

export const sellPropertyValidations = (inputValues) => {
    const errors = {};

    // Name validations
    if (inputValues.name === "") {
        errors.name = "Name is required."
    } else if (inputValues.name.length < 2) {
        errors.name = "Name is more than 2 charactars."
    } else if (inputValues.name.length >= 50) {
        errors.name = "Name is not more than 50 charactars."
    }

    // Phone number validations
    if (inputValues.phone === "") {
        errors.phone = "Phone number is required."
    } else if (!inputValues.phone.match(phonePattern)) {
        errors.phone = "Please enter a valid Phone number."
    }

    // Purchase type validations
    if (inputValues.sellRent === "") {
        errors.sellRent = "Please select any one type."
    }

    // Property type validations
    if (inputValues.propertyType === "") {
        errors.propertyType = "Please select property type."
    }

    // Address validations
    if (inputValues.address === "") {
        errors.address = "Address is required."
    } else if (inputValues.address.length < 5) {
        errors.address = "Address is more than 5 charactars."
    } else if (inputValues.address.length >= 250) {
        errors.address = "Address is not more than 250 charactars."
    }

    // Propety details validations
    if (inputValues.propertyDetails === "") {
        errors.propertyDetails = "Propety details is required."
    } else if (inputValues.propertyDetails.length < 5) {
        errors.propertyDetails = "Propety details is more than 5 charactars."
    } else if (inputValues.propertyDetails.length >= 250) {
        errors.propertyDetails = "Propety details is not more than 250 charactars."
    }

    return errors;
}

export const feedbackValidation = (inputValues) => {
    const errors = [];

    if (inputValues.name === "") {
        errors.name = "Name is required."
    }

    if (inputValues.rating === "") {
        errors.rating = "Rating is required."
    }

    if (inputValues.feedback === "") {
        errors.feedback = "Feedback is required."
    } else if (inputValues.feedback.length >= 250) {
        errors.feedback = "Feedback is not more than 250 charactars."
    }

    return errors;
}

export const propertyValidation = (inputValues) => {
    const errors = [];

    if (inputValues.title === "") {
        errors.title = "Title is required."
    } else if (!inputValues.title.match(propertyTitlePattern)) {
        errors.title = "Title not allowd blank space around it."
    } else if (!inputValues.title.match(noSpacialChar)){
        errors.title = "Title not allowd spacial characters."
    }

        if (inputValues.subtitle === "") {
            errors.subtitle = "Subtitle is required."
        }

    if (inputValues.location === "") {
        errors.location = "Location is required."
    }

    if (inputValues.sellRent === "") {
        errors.sellRent = "Sell/Rent is required."
    }

    if (inputValues.propertyType === "") {
        errors.propertyType = "Property Type is required."
    }

    if (inputValues.description === "") {
        errors.description = "Description is required."
    }

    if (inputValues.address === "") {
        errors.address = "Address is required."
    }

    return errors;
}
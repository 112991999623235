import React, { useState } from 'react';
import { userRegisterValidations } from '../../validations/validation';
import { useNavigate } from 'react-router-dom';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const Register = () => {

    const initRegisterForm = { name: '', email: '', password: '', confirmPassword: '' };
    const [registerData, setData] = useState(initRegisterForm);
    const [errors, setErrors] = useState({});
    const routerNavigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPass] = useState(false);

    const handleHideShowPass = (title) => {
        if (title === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPass(!showConfirmPassword)
        }
    };

    const handleChange = (e) => {
        setData({ ...registerData, [e.target.name]: e.target.value });
    }

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        setErrors(userRegisterValidations(registerData));
        e.preventDefault();
        if (isEmpty(userRegisterValidations(registerData))) {
            setLoading(true)
            await axios.post(`${process.env.REACT_APP_SERVER_ADMIN}/adminRegister`, registerData)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        successToaster(response.data.message)
                        routerNavigate('/admin/users');
                    }
                }).catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        } else {
            return
        }
    }

    return (
        <div style={{ backgroundColor: "#212333", backgroundImage: "linear-gradient(180deg, #3c4b64 0%, #212333 100%)" }}>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <Helmet>
                <title>Shlok Shakti - Add new admin</title>
                <meta name='description' content='Shlok shakrti add new admin' />
            </Helmet>
            <div className="container vh-100 d-flex align-items-center py-3">
                <div className="col-12 col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto p-0">
                    <div className="card p-4 rounded-2">
                        <h2 className="my-4 text-dark-blue fw-bold text-center">Add new user</h2>
                        <form onSubmit={handleSubmit} method='POST'>
                            <div>
                                <label htmlFor="name" className="label">Name</label>
                                <input type='text' placeholder='Name' id='name' onChange={handleChange} value={registerData.name} name='name' className={'col-12 input mb-4 ' + (errors.name ? 'error-input' : '')} />
                                {errors.name ?
                                    <span className='error-font'>{errors.name}</span>
                                    : ''
                                }
                            </div>
                            <div>
                                <label htmlFor="email" className="label">Email</label>
                                <input type='text' placeholder='Email' id='email' onChange={handleChange} value={registerData.email} name='email' className={'col-12 input mb-4 ' + (errors.email ? 'error-input' : '')} />
                                {errors.email ?
                                    <span className='error-font'>{errors.email}</span>
                                    : ''
                                }
                            </div>
                            <div className='input-box'>
                                <label htmlFor="password" className="label">Password</label>
                                <input type={showPassword ? 'text' : 'password'} placeholder='Password' id='password' onChange={handleChange} value={registerData.password} name='password' className={'col-12 input mb-4 ' + (errors.password ? 'error-input' : '')} />
                                {
                                    showPassword ?
                                        <i className="bi bi-eye-fill show-hide-icon" onClick={() => { handleHideShowPass('password') }} role='button'></i> :
                                        <i className="bi bi-eye-slash-fill show-hide-icon" onClick={() => { handleHideShowPass('password') }} role='button'></i>
                                }
                            </div>
                            {errors.password ?
                                <span className='error-font'>{errors.password}</span>
                                : ''
                            }
                            <div className='input-box'>
                                <label htmlFor="confirmPassword" className="label">Confirm Password</label>
                                <input type={showConfirmPassword ? 'text' : 'password'} placeholder='Confirm Password' id='confirmPassword' onChange={handleChange} value={registerData.confirmPassword} name='confirmPassword' className={'col-12 input mb-4 ' + (errors.confirmPassword ? 'error-input' : '')} />
                                {
                                    showConfirmPassword ?
                                        <i className="bi bi-eye-fill show-hide-icon" onClick={() => { handleHideShowPass('confirm password') }} role='button'></i> :
                                        <i className="bi bi-eye-slash-fill show-hide-icon" onClick={() => { handleHideShowPass('confirm password') }} role='button'></i>
                                }
                            </div>
                            {errors.confirmPassword ?
                                <span className='error-font'>{errors.confirmPassword}</span>
                                : ''
                            }
                            <div className="w-100 py-3">
                                <button type='submit' className='mb-2 col-12 btn btn-yellow'>Sign up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;
/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate, useParams } from 'react-router-dom';
import './property-details.css';
import flag from '../../assets/Images/flag.png';
import pdfDownload from '../../assets/Images/pdf-download.png';
import axios from 'axios';
import { useRef, useState } from 'react';
import { errorToaster } from '../../components/toaster/toaster';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Whirligig from 'react-whirligig';
import { DefaultPlayer as Video } from 'react-html5video';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/loader/loader';

const PropertyDetails = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const [propertyDetails, setPropertyDetails] = useState()
    const [allProperties, setAllProperties] = useState()
    const [videos, setVideos] = useState()
    const [images, setImages] = useState()
    const [pdf, setPdf] = useState()
    const [show, setShow] = useState(false);
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const nearByPropertyRef = useRef();
    const [loading, setLoading] = useState(true);

    const whatsappNumber = '+919537853025';
    const whatsappText = `Hi,

Interested in this property: http://localhost:3000/property-details/${id},

Could you provide more details and guide me on the next steps?

Thanks!`;

    useEffect(() => {
        getAllProperties()
        getPropertyData()
    }, [])

    const onMapLoad = (map) => {
        setMapRef(map);
    };

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyDataById/${id.replace(/-/g, ' ')}`)
            .then(res => {
                setLoading(false);
                setPropertyDetails({ ...res.data, ...res.data.propertyDetails[0] })
                if (res && res.data) {
                    getPropertyImages(res.data._id)
                    getPropertyVideos(res.data._id)
                    getPropertyPdf(res.data._id)
                }
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getAllProperties = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then(res => {
                setLoading(false);
                setAllProperties(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyImages = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyImages/${propertyId}`)
            .then(res => {
                setLoading(false);
                setImages(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyPdf = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyPdf/${propertyId}`)
            .then(res => {
                setLoading(false);
                setPdf(res.data[0])
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyVideos = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyVideos/${propertyId}`)
            .then(res => {
                setLoading(false);
                setVideos(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();

    const handleCloseModal = () => setShow(false);
    const handleShowImage = () => setShow(true);

    const handleMarkerClick = (id, data) => {
        mapRef?.panTo({ lat: parseFloat(data.lat), lng: parseFloat(data.lng) });
        setInfoWindowData({ id, data });
        setIsOpen(true);
    };

    const scrollToNearByProperties = (ref) => {
        window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' })
    }

    return (
        <div>
            {loading ? <Loader /> : ''}
            <Helmet>
                <title>{propertyDetails?.title}</title>
                <meta name='description' content={propertyDetails?.description} />
            </Helmet>
            <div className='container-lg mb-5 mt-66'>
                <div className='d-flex align-items-center mb-4 pt-3'>
                    <h6 className='text-black mb-0' role='button' onClick={() => navigate(-1)}>
                        <i className="bi bi-chevron-left me-2 text-black back-icon"></i> Back to Listing</h6>
                </div>
                <div className='row mb-4'>
                    <div className='col-12 col-lg-5 pe-lg-5'>
                        <div className='col-12 p-1' style={{ maxHeight: '300px', overflow: 'hidden' }}>
                            {
                                images && images[0]?.filePath ?
                                    <img src={images && images[0]?.filePath} className='col-12 h-100 w-100 object-fit-cover' alt="" onClick={handleShowImage} role='button' />
                                    :
                                    <div className='col-12 d-flex align-items-center justify-content-center border' style={{ height: '150px' }}>
                                        No image
                                    </div>
                            }
                        </div>
                        <div className='d-flex'>
                            <div className='col-4 p-1' style={{ maxHeight: '120px' }}>
                                {
                                    images && images[1]?.filePath ?
                                        <img src={images && images[1]?.filePath} className='col-12 h-100 object-fit-cover' alt="" onClick={handleShowImage} role='button' />
                                        :
                                        <div className='col-12 d-flex align-items-center justify-content-center border' style={{ height: '100%', minHeight: '100px' }}>
                                            No image
                                        </div>
                                }
                            </div>
                            <div className='col-4 p-1' style={{ maxHeight: '120px' }}>
                                {
                                    images && images[2]?.filePath ?
                                        <img src={images && images[2]?.filePath} className='col-12 h-100 w-100 object-fit-cover' alt="" onClick={handleShowImage} role='button' />
                                        :
                                        <div className='col-12 d-flex align-items-center justify-content-center border' style={{ height: '100%', minHeight: '100px' }}>
                                            No image
                                        </div>
                                }
                            </div>
                            <div className='col-4 p-1 position-relative' style={{ maxHeight: '120px' }}>
                                {
                                    images && images[3]?.filePath ?
                                        <img src={images ? images[3]?.filePath : ''} className='col-12 h-100 w-100 object-fit-cover' alt="" onClick={handleShowImage} role='button' />
                                        :
                                        <div className='col-12 d-flex align-items-center justify-content-center border' style={{ minHeight: '100px', height: '100%' }}>
                                            No image
                                        </div>
                                }
                                {
                                    images?.length - 4 > 0 ?
                                        <div className='more-image-bg' onClick={handleShowImage} role='button'>
                                            <div className='more-image text-center'>
                                                <h6 className='text-white mb-1'>{images?.length - 4}<span className='small'>+</span></h6>
                                                <h6 className='text-white'>Photos</h6>
                                            </div>
                                        </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleCloseModal} className='view-images-modal' centered>
                        <Whirligig
                            visibleSlides={1}
                            ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
                            preventScroll='false'
                            className="rounded slider-box"
                            style={{ maxHeight: 'calc(100vh - 100px)' }}
                        >
                            {
                                images?.map((res, index) => (
                                    <img key={index} className='h-100 w-100 object-fit-contain' src={res?.filePath} alt="" />
                                ))
                            }
                            {
                                videos?.map((res, index) => (
                                    <Video key={index} className="h-100 w-100 object-fit-contain" autoPlay loop controls={[]} muted>
                                        <source src={res.filePath} type='video/mp4' />
                                    </Video>
                                ))
                            }
                        </Whirligig>
                        <i onClick={prev} className='indicator-l bi bi-caret-left fs-3' role='button'></i>
                        <i onClick={next} className='indicator-r bi bi-caret-right fs-3' role='button'></i>
                    </Modal>
                    <div className='col-12 col-lg-7 mt-4 mt-lg-0 pe-lg-0'>
                        <h4 className='fw-bold mb-1 fs-3 text-yellow'>{propertyDetails?.title}</h4>
                        <p className='text-lightgray mb-2 fs-5'>{propertyDetails?.subtitle}</p>

                        {
                            propertyDetails?.price ?
                                <div className='mb-2'>
                                    <i className="bi bi-currency-rupee fs-5 text-black"></i>
                                    <span className='fw-bold text-black fs-5'>{propertyDetails?.price}</span>
                                </div>
                                : ''
                        }

                        <div className='d-sm-flex flex-wrap mb-3'>
                            <div className='d-flex align-items-center me-3 pe-3 py-1 mt-1'>
                                <i className="bi bi-tags-fill text-yellow me-2"></i>
                                <p className='m-0 text-black text-capitalize'>{propertyDetails?.property_type}</p>
                            </div>
                            <div className='d-flex align-items-center me-3 pe-3 py-1 mt-1'>
                                <img src={flag} height={14} className='me-2' alt="" />
                                <p className='m-0 text-black text-capitalize'>{propertyDetails?.sell_rent}</p>
                            </div>
                            <div className='d-flex align-items-center me-3 pe-3 py-1 mt-1'>
                                <i className="bi bi-geo-alt-fill text-yellow me-2"></i>
                                <p className='m-0 text-black text-capitalize' onClick={() => { scrollToNearByProperties(nearByPropertyRef) }} role='button'>{propertyDetails?.location}</p>
                            </div>
                        </div>

                        <p className='text-lightgray mb-4 mt-3 fs-5'>
                            <span className='fw-bold text-black'>Description:</span> {propertyDetails?.description}
                        </p>
                        <Link to={'/contact-us'} className='btn btn-outline-yellow px-4 py-2 text-yellow me-3'>Contact Now</Link>
                        <a href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(whatsappText)}`} target='blank' className='btn btn-yellow px-4 py-2'>WhatsApp</a>
                    </div>
                </div>
                <div className='rounded-4 p-sm-32' style={{ background: "#F7F7F7" }}>
                    <h3 className='mb-4 text-black'>More Details</h3>
                    {
                        propertyDetails?.price ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Price Breakup</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.price}</h6></div>
                            </div>
                            : ''
                    }
                    <div className='d-flex mb-4'>
                        <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Preleased</h6></div>
                        <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.preleased.toString() === 'true' ? 'Preleased' : 'Not Preleased'}</h6></div>
                    </div>
                    {
                        propertyDetails?.plot_area ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Plot Area</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.plot_area}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.construction_area ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Construction Area</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.construction_area}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.bhk ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>BHK</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.bhk}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.facing ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Facing</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.facing}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.furnished ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Furnishing</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.furnished}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.size ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Size</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.size}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.total_floor ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Total Floor</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.total_floor}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.amenities_project ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Amenities Project</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10' style={{ whiteSpace: 'pre-wrap', }}><h6 className='text-black'>{propertyDetails?.amenities_project}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.property_age ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Property Age</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.property_age}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.dimensions ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Dimensions</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.dimensions}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.floor ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Floor</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.floor}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.height ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Height</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.height}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.land_type ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Land Type</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.land_type}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.plot_type ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Plot Type</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.plot_type}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.possession_date ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Possession Date</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.possession_date}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.average_price ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Average price</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.average_price}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.configuration ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Configuration</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.configuration}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.possession_starts ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Possession starts</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.possession_starts}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.launch_date ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Launch date</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.launch_date}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.project_starts ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Project starts</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.project_starts}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.total_tower ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Total tower</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.total_tower}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.total_units ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Total units</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.total_units}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.rera_no ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Rera no</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.rera_no}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.amenities ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Amenities</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10' style={{ whiteSpace: 'pre-wrap', }}><h6 className='text-black'>{propertyDetails?.amenities}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.near_by_places ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Near by places</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.near_by_places}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.about_developer ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>About developer</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.about_developer}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.project_area ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Project area</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.project_area}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.available_units ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Available units</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.available_units}</h6></div>
                            </div> : ''
                    }
                    {
                        pdf && pdf.filePath ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Downloas brochure</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'><a href={pdf.filePath}><img src={pdfDownload} alt="" height={32} /></a></h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.lease_period ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Possession Date</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.lease_period}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.lock_period ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Possession Date</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.lock_period}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.tenant_details ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Possession Date</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.tenant_details}</h6></div>
                            </div> : ''
                    }
                    {
                        propertyDetails?.address ?
                            <div className='d-flex mb-4'>
                                <div className='col-6 col-sm-4 col-md-3 col-xl-2'><h6 className='text-darkgray'>Address</h6></div>
                                <div className='col-6 col-sm-8 col-md-9 col-xl-10'><h6 className='text-black'>{propertyDetails?.address}</h6></div>
                            </div> : ''
                    }
                    <Link to={'/contact-us'} className='btn btn-outline-yellow px-4 py-2 text-yellow'>Contact Now</Link>
                </div>
                <div className='mt-4 nearby-props' ref={nearByPropertyRef}>
                    <h3 className='mb-4 text-black'>Near by Properties</h3>
                    {
                        propertyDetails ?
                            <div style={{ height: '400px' }}>
                                <GoogleMap
                                    mapContainerClassName="h-100 w-100"
                                    onLoad={onMapLoad}
                                    center={{
                                        lat: propertyDetails?.lat,
                                        lng: propertyDetails?.lng
                                    }}
                                    zoom={16}
                                >
                                    {allProperties?.map((propertyData, index) => (
                                        <Marker
                                            key={index}
                                            position={{ lat: parseFloat(propertyData.lat), lng: parseFloat(propertyData.lng) }}
                                            onClick={() => {
                                                handleMarkerClick(index, propertyData);
                                            }}
                                        >
                                            {isOpen && infoWindowData?.id === index && (
                                                <InfoWindow
                                                    onCloseClick={() => {
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    <div onClick={() => { navigate(`/property-details/${infoWindowData.data.title.replace(/\s+/g, '-')}`); window.location.reload() }} style={{ maxWidth: '300px', minWidth: '250px' }}>
                                                        <img src={propertyData.filePath} className='h-100 w-100 props-image' alt="" />
                                                        <div className='p-3 bg-white position-relative'>
                                                            <p className='m-0 text-black mb-2' style={{ fontSize: '14px' }}><i className="bi bi-geo-alt-fill text-yellow me-1"></i>{infoWindowData.data.location}</p>
                                                            <h6 className='text-capitalize m-0'>{infoWindowData.data.title}</h6>
                                                        </div>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </Marker>
                                    ))}
                                </GoogleMap>
                            </div> : ''
                    }
                </div>
            </div>
        </div>
    );
}

export default PropertyDetails;

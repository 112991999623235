/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";

import './users.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import Loader from '../../components/loader/loader';

const Users = () => {

    const gridRef = useRef();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState();
    const [loading, setLoading] = useState(true);
    const [columnDefs, setColumnDefs] = useState([]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        flex: 1,
        cellClass: 'no-border'
    }));

    const successToaster = (response) => {
        toast.success(response, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        var token = JSON.parse(localStorage.getItem('apiKey'));
        const decoded = jwt_decode(token);

        await axios.get(`${process.env.REACT_APP_SERVER_ADMIN}/getAllUsers`)
            .then(response => {
                setLoading(false);
                setRowData(response.data)
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })

        setColumnDefs([
            { headerName: 'Name', field: 'name', maxWidth: 300, minWidth: 120 },
            { headerName: 'Email', field: 'email', minWidth: 250 },
            {
                headerName: 'Actions', maxWidth: 100, minWidth: 100, sortable: false, filter: false, cellRenderer: params => {
                    return (
                        <div>
                            <div className={'ms-3 ' + (params.data.email === decoded.response.email ? 'disabled-box ' : '') + (params.data.email === 'ap@flitzinteractive.com' ? 'd-none' : '')}>
                                <i className={"bi bi-trash fs-5 " + (params.data.email === decoded.response.email ? 'disabled-icon' : 'clickable-icon')} onClick={() => { deleteUser(params) }}></i>
                            </div>
                        </div>
                    )
                }
            },
        ])
    }

    const deleteUser = (params) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                await axios.delete(`${process.env.REACT_APP_SERVER_ADMIN}/deleteUser/${params.data._id}`)
                    .then(response => {
                        setLoading(false);
                        if (response.data.error) {
                            errorToaster(response.data.message)
                        } else {
                            successToaster(response.data.message)
                            getUsers();
                        }
                    }).catch(error => {
                        setLoading(false);
                        errorToaster(error)
                    })
            } else {
                return
            }
        })
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className="d-flex py-2 px-3 admin-title align-items-center justify-content-between" >
                    <h4 className="pl-3 mb-0 pb-0">Admin List</h4>
                    <i className="bi bi-plus-square fs-3 add-admin" role='button' onClick={() => navigate('/register')}></i>
                </div >
                <div className="ag-theme-alpine" style={{ width: '100%', height: 'calc(100vh - 158px)' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        paginationAutoPageSize={true}
                        pagination={true}
                        animateRows={true}
                        rowSelection='multiple'
                    />
                </div>
            </div>
        </div >
    );
}

export default Users;
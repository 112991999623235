/* eslint-disable no-undef */
import './map-view.css';
import SearchProperty from '../../components/search-property/search-property';
import axios from 'axios';
import { errorToaster } from '../../components/toaster/toaster';
import { useState } from 'react';
import { useEffect } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useMemo } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/loader';

const MapView = () => {

    const [allProperties, setAllProperties] = useState();
    const [filteredProperties, setFilteredProperties] = useState();
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const center = useMemo(() => ({ lat: 23.2156, lng: 72.6369 }), []);
    const [zoomMap, setZoomMap] = useState(11);
    const [selectedPropertyType, setSelectedPropertyType] = useState('')
    const [buyRent, setBuyRent] = useState('');
    const [landType, setLandType] = useState('');
    const [bhk, setBhk] = useState(0);
    const [preleased, setPreleased] = useState('');
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPropertyData();
    }, [])

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyData`)
            .then(res => {
                setLoading(false);
                setAllProperties(res.data)
                setFilteredProperties(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const onMapLoad = (map) => {
        setMapRef(map);
    };

    const handleMarkerHover = (id, data) => {
        setInfoWindowData({ id, data });
        setIsOpen(true);
    };

    const getSelectedCategory = (category) => {
        setLandType('');
        setBhk(0);
        setSelectedPropertyType(category)

        const filterCondition = (res) => {
            return (
                (!category || res.property_type === category) &&
                (!buyRent || res.sell_rent === buyRent) &&
                (!preleased || res.preleased?.toString() === preleased)
            );
        };

        setFilteredProperties(allProperties?.filter(filterCondition));

    }

    const changeBuyRentType = (buyRentType) => {
        setBuyRent(buyRentType)

        const filterCondition = (res) => {
            const bhkLandTypeCondition = (bhk, landType) => bhk || landType ? (res.bhk === bhk || res.land_type === landType) : true;
            const preleasedCondition = preleased ? res.preleased?.toString() === preleased : true;
            const propertyTypeCondition = selectedPropertyType ? res.property_type === selectedPropertyType : true;

            if (buyRentType === '') {
                return bhkLandTypeCondition(bhk, landType) && preleasedCondition && propertyTypeCondition;
            } else {
                const sellRentTypeCondition = res.sell_rent === buyRentType;
                return bhkLandTypeCondition(bhk, landType) && preleasedCondition && propertyTypeCondition && sellRentTypeCondition;
            }
        };

        setFilteredProperties(allProperties?.filter(filterCondition));
    }

    const changeBhk = (bhk) => {
        setBhk(Number(bhk))

        const filterCondition = (res) => {
            const isMatchingBhk = Number(bhk) === 0 || res.bhk === Number(bhk);
            const preleasedCondition = preleased ? res.preleased?.toString() === preleased : true;
            const buyRentCondition = buyRent ? res.sell_rent === buyRent : true;
            const propertyTypeCondition = selectedPropertyType ? res.property_type === selectedPropertyType : true;

            return isMatchingBhk && preleasedCondition && buyRentCondition && propertyTypeCondition;
        };

        setFilteredProperties(allProperties?.filter(filterCondition));
    }

    const changeLandType = (landType) => {
        setLandType(landType)

        const filterCondition = (res) => {
            const isMatchingLandType = landType === '' || res.land_type === landType;
            const preleasedCondition = preleased ? res.preleased?.toString() === preleased : true;
            const buyRentCondition = buyRent ? res.sell_rent === buyRent : true;
            const propertyTypeCondition = selectedPropertyType ? res.property_type === selectedPropertyType : true;

            return isMatchingLandType && preleasedCondition && buyRentCondition && propertyTypeCondition;
        };

        setFilteredProperties(allProperties?.filter(filterCondition));
    }

    const changePreleased = (preleased) => {
        setPreleased(preleased)

        const filterCondition = (res) => {
            const hasBHKOrLandType = bhk || landType;

            return (
                (!preleased || res.preleased?.toString() === preleased) &&
                (!hasBHKOrLandType || (res.bhk === bhk || res.land_type === landType)) &&
                (!buyRent || res.sell_rent === buyRent) &&
                (!selectedPropertyType || res.property_type === selectedPropertyType)
            );
        };

        const filteredProperties = allProperties?.filter(filterCondition);
        setFilteredProperties(filteredProperties);

    }

    const searchProperty = (searchedAddress) => {
        geocodeByAddress(searchedAddress)
            .then(results =>
                getLatLng(results[0])
            )
            .then(latLng => {
                mapRef?.panTo(latLng)
                setZoomMap(16)
            }
            ).catch(error => errorToaster(error))
    }

    return (
        <div>
            {loading ? <Loader height={'100%'} /> : ''}
            <Helmet>
                <title>Shlok Shakti - Map view</title>
                <meta name='description' content='Shlok shakrti map view' />
            </Helmet>
            <SearchProperty showBackBtn={true} getPropertyType={getSelectedCategory} getSearchedKeyWord={searchProperty} selectedBuyRent={changeBuyRentType} selectedBhk={changeBhk} selectedLandType={changeLandType} selectedPreleased={changePreleased} title={'mapView'} />
            <div className='map-view' style={{ height: 'calc(100vh - 87px)' }}>
                <GoogleMap
                    mapContainerClassName="h-100 w-100"
                    center={center}
                    onLoad={onMapLoad}
                    zoom={zoomMap}
                    onClick={() => setIsOpen(false)}
                >
                    {filteredProperties?.map((propertyData, index) => (
                        <Marker
                            key={index}
                            position={{ lat: parseFloat(propertyData.lat), lng: parseFloat(propertyData.lng) }}
                            onMouseEnter={() => {
                                handleMarkerHover(index, propertyData);
                            }}
                            onClick={() => {
                                handleMarkerHover(index, propertyData);
                            }}
                        >
                            {isOpen && infoWindowData?.id === index && (
                                <InfoWindow
                                    onCloseClick={() => {
                                        setIsOpen(false);
                                    }}
                                >
                                    <div onClick={() => { navigate(`/property-details/${infoWindowData.data.title.replace(/\s+/g, '-')}`) }} style={{ maxWidth: '300px', minWidth: '250px' }}>
                                        <img src={propertyData.filePath} className='h-100 w-100 info-image' alt="" />
                                        <div className='p-3 bg-white position-relative'>
                                            <p className='m-0 text-black mb-2' style={{ fontSize: '14px' }}><i className="bi bi-geo-alt-fill text-yellow me-1"></i>{infoWindowData.data.location}</p>
                                            <h6 className='text-capitalize m-0'>{infoWindowData.data.title}</h6>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    ))}
                </GoogleMap>
            </div>
        </div >
    );
}

export default MapView
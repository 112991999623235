/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";


export const successToaster = (response) => {
    toast.success(response, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export const errorToaster = (response) => {
    toast.error(response, {
        position: toast.POSITION.TOP_RIGHT
    });
}


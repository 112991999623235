/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './view-property.css';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { errorToaster, successToaster } from '../../components/toaster/toaster';
import axios from 'axios';
import { DefaultPlayer as Video } from 'react-html5video';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Modal from 'react-bootstrap/Modal';
import Whirligig from 'react-whirligig';
import Loader from '../../components/loader/loader';
import { propertyValidation } from '../../validations/validation';
import pdfDownload from '../../assets/Images/pdf-download.png';

const ViewProperty = () => {

    const initUpdatePropertyForm = { title: '', subtitle: '', location: '', sell_rent: '', property_type: '', plot_area: '', construction_area: '', bhk: 0, facing: '', address: '', price: '', furnished: '', description: '', size: '', total_floor: 0, amenities_project: '', property_age: '', dimensions: '', height: '', land_type: null, plot_type: '', possession_date: '', possession_starts: '' }
    let { id } = useParams();
    const { state } = useLocation();
    const [propertyData, setPropertyData] = useState(initUpdatePropertyForm);
    const [isEditable, setIsEditable] = useState(false);
    const [oldImages, setOldImages] = useState();
    const [oldVideos, setOldVideos] = useState();
    const [pdfFile, setPdfFile] = useState();
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedVideos, setselectedVideos] = useState([]);
    const [address, setAddress] = useState('');
    const [show, setShow] = useState(false);
    const [propertyId, setPropertyId] = useState();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});


    const navigate = useNavigate();

    useEffect(() => {
        getPropertyData();
    }, [])

    const getPropertyData = async () => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyDataById/${id.replace(/-/g, ' ')}`)
            .then(response => {
                setLoading(false);
                if (response.data.error) {
                    errorToaster(response.data.message)
                } else {
                    setPropertyId(response.data._id)
                    response.data.propertyDetails.forEach(element => {
                        setPropertyData({ ...response.data, ...element })
                        setAddress(response.data.address)
                    });
                    if (state && state.isEdit) {
                        setIsEditable(true)
                    }
                    getPropertyImages(response.data._id)
                    getPropertyVideos(response.data._id)
                    getPropertyPdf(response.data._id)
                }
            }).catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyImages = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyImages/${propertyId}`)
            .then(res => {
                setLoading(false);
                setOldImages(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyVideos = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyVideos/${propertyId}`)
            .then(res => {
                setLoading(false);
                setOldVideos(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const getPropertyPdf = async (propertyId) => {
        await axios.get(`${process.env.REACT_APP_SERVER_PROPERTY}/getPropertyPdf/${propertyId}`)
            .then(res => {
                setLoading(false);
                setPdfFile(res.data)
            })
            .catch(error => {
                setLoading(false);
                errorToaster(error)
            })
    }

    const handleInputChange = (e) => {
        if (e.target.name === 'title') {
            let title = e.target.value.replace(/\s+/g, ' ');
            setPropertyData({ ...propertyData, title: title })
        } else {
            setPropertyData({ ...propertyData, [e.target.name]: e.target.value })
        }
    }

    const getImagePath = (e) => {
        let seletedFile = e.target.files;
        if (selectedFiles.length > 10) {
            errorToaster('Maximum images limit 10.')
        } else {
            for (let i = 0; i < seletedFile.length; i++) {
                if (seletedFile[i]) {
                    if (seletedFile[i].size < 7340032) {
                        if (seletedFile[i].type === 'image/jpg' || seletedFile[i].type === 'image/png' || seletedFile[i].type === 'image/jpeg') {
                            setSelectedFiles(selectedFiles => [...selectedFiles, seletedFile[i]])
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setSelectedImages(selectedImages => [...selectedImages, e.target.result]);
                            }
                            reader.readAsDataURL(seletedFile[i])
                        } else {
                            errorToaster('Invalid image type.')
                        }
                    } else {
                        errorToaster('File size maximum 7mb required.')
                    }
                } else {
                    return;
                }
            }
        }
    }

    const getFilePath = (e) => {
        let seletedFile = e.target.files[0];
        if (seletedFile.size < 209715200) {
            if (seletedFile.type === 'application/pdf') {
                if (pdfFile.length >= 1) {
                    var file = pdfFile.slice(0, 1)
                    setPdfFile([seletedFile, ...file])
                } else {
                    setPdfFile(seletedFile)
                }
            } else {
                errorToaster('Please upload pdf only.')
            }
        } else {
            errorToaster('File size maximum 200mb required.')
        }
    }

    const getVideoPath = (e) => {
        let seletedFile = e.target.files;
        if (selectedFiles.length >= 1) {
            errorToaster('Only one video required.')
        } else {
            for (let i = 0; i < seletedFile.length; i++) {
                if (seletedFile[i]) {
                    if (seletedFile[i].size < 52428800) {
                        if (seletedFile[i].type === 'video/mp4' || seletedFile[i].type === 'video/x-matroska') {
                            setSelectedFiles([...selectedFiles, seletedFile[i]])
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                setselectedVideos([...selectedVideos, e.target.result]);
                            }
                            reader.readAsDataURL(seletedFile[i])
                        } else {
                            errorToaster('Invalid video type.')
                        }
                    } else {
                        errorToaster('File size maximum 50mb required.')
                    }
                } else {
                    return;
                }
            }
        }
    }

    const removeImage = (index) => {
        var images = [...selectedImages];
        var files = [...selectedFiles];
        images.splice(index, 1);
        files.splice(index, 1);
        setSelectedImages(images);
        setSelectedFiles(files);
    }

    const removeVideo = (index) => {
        var videos = [...selectedVideos];
        var files = [...selectedFiles];
        videos.splice(index, 1);
        files.splice(index, 1);
        setselectedVideos(videos);
        setSelectedFiles(files);
    }

    const deleteFile = async (id, fileType) => {
        setLoading(true)
        if (fileType === 'image') {
            await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteImageFile/${id}`)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        getPropertyImages(propertyId);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        } else {
            await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteVideoFile/${id}`)
                .then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        errorToaster(response.data.message)
                    } else {
                        getPropertyVideos(propertyId);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    errorToaster(error)
                })
        }
    }

    const isEmpty = (errors) => {
        for (const key in errors) {
            return false;
        }
        return true;
    }

    const handleUpdateProperty = async (e) => {
        e.preventDefault();
        setErrors(propertyValidation({ ...propertyData, address }))
        if (isEmpty(propertyValidation(propertyData))) {
            if ((selectedImages.length + selectedVideos.length + oldImages.length + oldVideos.length) > 11 || (oldImages.length + selectedImages.length) > 10 || (oldVideos.length + selectedVideos.length > 1)) {
                errorToaster('Maximum 1 video and 10 images required.');
            } else {
                if ((selectedImages.length + selectedVideos.length + oldImages.length + oldVideos.length) === 0) {
                    errorToaster('Please add some images or video.');
                } else {
                    setLoading(true)
                    const formData = new FormData();
                    selectedFiles.push(pdfFile)
                    for (let index = 0; index < selectedFiles.length; index++) {
                        const element = selectedFiles[index];
                        formData.append('allMedia', element)
                    }
                    setPdfFile([])
                    formData.append('propertyData', JSON.stringify(propertyData))
                    await axios.put(`${process.env.REACT_APP_SERVER_PROPERTY}/updatePropertyData/${propertyId}`, formData)
                        .then(res => {
                            setLoading(false);
                            if (res.data.error) {
                                errorToaster(res.data.message)
                            } else {
                                successToaster(res.data.message)
                                navigate('/admin/properties-admin')
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            errorToaster(error)
                        })
                }
            }
        } else {
            return;
        }
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                await axios.delete(`${process.env.REACT_APP_SERVER_PROPERTY}/deleteProperty/${propertyId}`)
                    .then((response) => {
                        setLoading(false);
                        if (response.data.error) {
                            errorToaster(response.data.message)
                        } else {
                            navigate('/admin/properties-admin');
                            successToaster(response.data.message)
                            oldImages?.forEach(async (imageData) => {
                                deleteFile(imageData.id, 'image')
                            })

                            oldVideos?.forEach(async (videoData) => {
                                deleteFile(videoData.id, 'video')
                            })
                        }
                    }).catch(error => {
                        setLoading(false);
                        errorToaster(error)
                    })
            } else {
                return;
            }
        })
    }

    const handleChange = (e) => {
        setAddress(e);
    };

    const handleSelect = (autoCompleteAddress) => {
        setAddress(autoCompleteAddress);
        geocodeByAddress(autoCompleteAddress)
            .then(results =>
                getLatLng(results[0])
            )
            .then(latLng =>
                setPropertyData({ ...propertyData, address: autoCompleteAddress, ...latLng })
            ).catch(error => errorToaster(error))
    };

    const cancelEditing = () => {
        setIsEditable(false);
        getPropertyData();
        if (state && state.isEdit) {
            navigate(-1);
        }
    }

    let whirligig;
    const next = () => whirligig.next();
    const prev = () => whirligig.prev();

    const handleCloseModal = () => setShow(false);
    const handleShowImage = () => setShow(true);

    return (
        <div>
            {loading ? <Loader height={'100%'} position={'absolute'} /> : ''}
            <div className='p-3'>
                <div className='mb-3 mb-md-0'>
                    <i className="bi bi-chevron-left back-icon" role="button" onClick={() => { navigate(-1) }}></i>
                </div>
                <div className='card col-12 col-md-10 col-xl-10 mx-auto bg-white p-4 rounded-3'>
                    <form onSubmit={handleUpdateProperty} method='put' className='row'>
                        <h4 className='text-center py-3 mb-3'>Property details</h4>
                        <div className='col-12 col-md-6'>
                            <h6 className='label'>Title</h6>
                            <input type="text" className='input mb-4' name='title' onChange={handleInputChange} value={propertyData.title ? propertyData.title : ''} readOnly={!isEditable} />
                            {errors.title ?
                                <span className='error-font'>{errors.title}</span>
                                : ''
                            }
                        </div>
                        <div className='col-12 col-md-6'>
                            <h6 className='label'>Subtitle</h6>
                            <input type="text" className='input mb-4' name='subtitle' onChange={handleInputChange} value={propertyData.subtitle ? propertyData.subtitle : ''} readOnly={!isEditable} />
                            {errors.subtitle ?
                                <span className='error-font'>{errors.subtitle}</span>
                                : ''
                            }
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <h6 className='label'>Location</h6>
                            <input type="text" className='input mb-4' name='location' onChange={handleInputChange} value={propertyData.location ? propertyData.location : ''} readOnly={!isEditable} />
                            {errors.location ?
                                <span className='error-font'>{errors.location}</span>
                                : ''
                            }
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <h6 className='label'>Sell/Rent</h6>
                            <input type="text" className='input mb-4' name='sell_rent' value={propertyData.sell_rent ? propertyData.sell_rent : ''} readOnly />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <h6 className='label'>Property type</h6>
                            <input type="text" className='input mb-4' name='property_type' value={propertyData.property_type ? propertyData.property_type : ''} readOnly />
                        </div>
                        {/* <div className='col-12 col-md-6 col-lg-4 mb-4'>
                            <h6 className='label'>Status</h6>
                            <input type="text" className='input' name='status' onChange={handleInputChange} value={propertyData.status ? propertyData.status : ''} readOnly={!isEditable} />
                        </div> */}
                        <div className='col-12 col-md-6 col-lg-4'>
                            <h6 className='label'>Status</h6>
                            <select className="input select-input mb-4" name='status' onChange={handleInputChange} value={propertyData.status} disabled={!isEditable}>
                                <option value='Available'>Available</option>
                                <option value='Sold'>Sold</option>
                            </select>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <h6 className='label'>Preleased</h6>
                            <input type="text" className='input mb-4' name='preleased' onChange={handleInputChange} value={propertyData.preleased ? 'preleased' : 'Not preleased'} readOnly />
                        </div>
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'sheds' || propertyData.property_type === 'industrials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Plot area</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='plot_area' value={propertyData.plot_area ? propertyData.plot_area : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'sheds' || propertyData.property_type === 'industrials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Construction area</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='construction_area' value={propertyData.construction_area ? propertyData.construction_area : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'flats' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>BHK</h6>
                                    <input type="number" className='input' onChange={handleInputChange} name='bhk' value={propertyData.bhk ? propertyData.bhk : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'flats' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'sheds' || propertyData.property_type === 'industrials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Facing</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='facing' value={propertyData.facing ? propertyData.facing : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'flats' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'industrials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Price</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='price' value={propertyData.price ? propertyData.price : ""} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'bungalows' || propertyData.property_type === 'flats' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'industrials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Furnished</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='furnished' value={propertyData.furnished ? propertyData.furnished : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'lands' || propertyData.property_type === 'flats' || propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'plots' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Size</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='size' value={propertyData.size ? propertyData.size : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'flats' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' ?
                                < div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Total floor</h6>
                                    <input type="number" className='input' onChange={handleInputChange} name='total_floor' value={propertyData.total_floor ? propertyData.total_floor : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'flats' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Amenities project</h6>
                                    <textarea type="text" className='input' onChange={handleInputChange} name='amenities_project' value={propertyData.amenities_project ? propertyData.amenities_project : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'flats' || propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'sheds' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Property age</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='property_age' value={propertyData.property_age ? propertyData.property_age : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'shops' || propertyData.property_type === 'offices' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Dimensions</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='dimensions' value={propertyData.dimensions ? propertyData.dimensions : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'offices' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Floor</h6>
                                    <input type="number" className='input' onChange={handleInputChange} name='floor' value={propertyData.floor ? propertyData.floor : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'shops' || propertyData.property_type === 'offices' || propertyData.property_type === 'sheds' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Height</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='height' value={propertyData.height ? propertyData.height : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'lands' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Zone type</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='land_type' value={propertyData.land_type ? propertyData.land_type : ''} readOnly />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'plots' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Plot type</h6>
                                    <input type="text" className='input' onChange={handleInputChange} name='plot_type' value={propertyData.plot_type ? propertyData.plot_type : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Possession date</h6>
                                    <input type='date' className='input' onChange={handleInputChange} name='possession_date' value={propertyData.possession_date ? propertyData.possession_date : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Average price</h6>
                                    <input type="text" className='input' name='average_price' onChange={handleInputChange} value={propertyData.average_price ? propertyData.average_price : ''} placeholder='Enter average price' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Configuration</h6>
                                    <input type="text" className='input' name='configuration' onChange={handleInputChange} value={propertyData.configuration ? propertyData.configuration : ''} placeholder='Enter configuration' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Possession starts</h6>
                                    <input type="text" className='input' name='possession_starts' onChange={handleInputChange} value={propertyData.possession_starts ? propertyData.possession_starts : ''} placeholder='Enter possession starts' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Launch date</h6>
                                    <input type="date" className='input' name='launch_date' onChange={handleInputChange} value={propertyData.launch_date ? propertyData.launch_date : ''} placeholder='Enter launch date' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Project starts</h6>
                                    <input type="text" className='input' name='project_starts' onChange={handleInputChange} value={propertyData.project_starts ? propertyData.project_starts : ''} placeholder='Enter project starts' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Total tower</h6>
                                    <input type="text" className='input' name='total_tower' onChange={handleInputChange} value={propertyData.total_tower ? propertyData.total_tower : ''} placeholder='Enter total tower' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Total units</h6>
                                    <input type="text" className='input' name='total_units' onChange={handleInputChange} value={propertyData.total_units ? propertyData.total_units : ''} placeholder='Enter total units' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Rera no</h6>
                                    <input type="number" className='input' name='rera_no' onChange={handleInputChange} value={propertyData.rera_no ? propertyData.rera_no : ''} placeholder='Enter rera no' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Amenities</h6>
                                    <textarea type="text" className='input' name='amenities' onChange={handleInputChange} value={propertyData.amenities ? propertyData.amenities : ''} placeholder='Enter amenities' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Near by places</h6>
                                    <input type="text" className='input' name='near_by_places' onChange={handleInputChange} value={propertyData.near_by_places ? propertyData.near_by_places : ''} placeholder='Enter near by places' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>About developer</h6>
                                    <input type="text" className='input' name='about_developer' onChange={handleInputChange} value={propertyData.about_developer ? propertyData.about_developer : ''} placeholder='Enter about developer' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'commercials' || propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Project area</h6>
                                    <input type="text" className='input' name='project_area' onChange={handleInputChange} value={propertyData.project_area ? propertyData.project_area : ''} placeholder='Enter project area' />
                                </div> : ''
                        }
                        {
                            propertyData.property_type === 'residentials' ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Available units</h6>
                                    <input type="text" className='input' name='available_units' onChange={handleInputChange} value={propertyData.available_units ? propertyData.available_units : ''} placeholder='Enter available units' />
                                </div> : ''
                        }
                        {
                            propertyData.lease_period ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Lease Period</h6>
                                    <input type="text" className='input' name='leasePeriod' onChange={handleInputChange} value={propertyData.lease_period ? propertyData.lease_period : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.lock_period ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>lockPeriod</h6>
                                    <input type="text" className='input' name='lockPeriod' onChange={handleInputChange} value={propertyData.lock_period ? propertyData.lock_period : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        {
                            propertyData.tenant_details ?
                                <div className='col-12 col-md-6 col-lg-4 mb-4'>
                                    <h6 className='label'>Tenant Details</h6>
                                    <input type="text" className='input' name='tenantDetails' onChange={handleInputChange} value={propertyData.tenant_details ? propertyData.tenant_details : ''} readOnly={!isEditable} />
                                </div> : ''
                        }
                        <div className='col-12 col-md-6 col-lg-4 mb-4'>
                            <h6 className='label'>Downloas brochure</h6>
                            <div className='d-flex align-items-center mt-2'>
                                {isEditable || (pdfFile && pdfFile.length === 0) ?
                                    < input type="file" name="downloasBrochure" accept="application/pdf" onChange={getFilePath} className='input me-2 p-2' />
                                    : ''
                                }
                                {(pdfFile && pdfFile.length >= 1) ? <a href={pdfFile[0].filePath}><img src={pdfDownload} alt="" height={32} /></a> : ''}
                            </div>
                        </div>
                        <div className='row p-0 m-0'>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className='col-12 col-md-6 mb-4'>
                                        <h6 className='label'>Address</h6>
                                        <div className='mb-4 position-relative'>
                                            <textarea rows={3} {...getInputProps({ placeholder: 'Enter address', className: 'input' })} readOnly={!isEditable} />
                                            <div className="map-search-autocomplete">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion, index) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                            key={index}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        {errors.address ?
                                            <span className='error-font'>{errors.address}</span>
                                            : ''
                                        }
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <div className='col-12 col-md-6'>
                                <h6 className='label'>Description</h6>
                                <textarea className='input mb-4' rows={3} onChange={handleInputChange} name='description' value={propertyData.description ? propertyData.description : ""} readOnly={!isEditable} />
                                {errors.description ?
                                    <span className='error-font'>{errors.description}</span>
                                    : ''
                                }
                            </div>
                        </div>
                        {
                            isEditable && (oldImages?.length + oldVideos?.length) < 11 ?
                                <div className='d-flex flex-wrap mt-2'>
                                    <div className='mb-4'>
                                        <label htmlFor='image_url' className='image-box d-flex align-items-center justify-content-center' role='button'>
                                            <i className="bi bi-card-image"></i>
                                            <input type="file" multiple name="imageUrl" accept="image/png, image/jpeg, image/jpg" onChange={getImagePath} id="image_url" className='d-none' />
                                        </label>
                                    </div>
                                    <div className='mb-4'>
                                        <label htmlFor='video_url' className='image-box d-flex align-items-center justify-content-center' role="button">
                                            <i className="bi bi-camera-video"></i>
                                            <input type="file" name="videoUrl" accept="video/mp4, video/x-matroska" onChange={getVideoPath} id="video_url" className='d-none' />
                                        </label>
                                    </div>
                                    {
                                        selectedImages.length ?
                                            selectedImages.map((imagePath, index) => (
                                                <div key={index} className='position-relative'>
                                                    <img src={imagePath} className='image-box border-0 mb-3' alt="" />
                                                    <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeImage(index) }}></i>
                                                </div>
                                            )) : ""
                                    }
                                    {
                                        selectedVideos.length ?
                                            selectedVideos.map((videoPath, index) => (
                                                <div key={index} className='position-relative'>
                                                    <Video className="image-box mb-3" controls={[]} muted>
                                                        <source className='h-100 w-100' src={videoPath} />
                                                    </Video>
                                                    <i className="bi bi-x-circle-fill text-danger close-btn" onClick={() => { removeVideo(index) }}></i>
                                                </div>
                                            )) : ""
                                    }
                                </div> : ''
                        }
                        <div className='d-flex flex-wrap mt-2'>
                            {
                                oldImages?.length ?
                                    oldImages.map((data, index) => (
                                        <div key={index} className='position-relative' onClick={handleShowImage} role='button' >
                                            <img src={data.filePath} className='image-box border border-secondary mb-3' alt="" />
                                            <i className={"bi bi-x-circle-fill text-danger close-btn " + (isEditable ? '' : 'd-none')} onClick={() => { deleteFile(data.id, 'image') }}></i>
                                        </div>
                                    )) : ''
                            }
                            {
                                oldVideos?.length ?
                                    oldVideos.map((data, index) => (
                                        <div key={index} onClick={handleShowImage} className='position-relative' role='button' >
                                            <Video className="image-box border border-secondary" controls={[]} muted>
                                                <source className='h-100 w-100' src={data.filePath} />
                                            </Video>
                                            <i className={"bi bi-x-circle-fill text-danger close-btn " + (isEditable ? '' : 'd-none')} onClick={() => { deleteFile(data.id, 'video') }}></i>
                                        </div>
                                    )) : ''
                            }
                        </div>
                        <Modal show={show} onHide={handleCloseModal} className='view-images-modal' centered>
                            <Whirligig
                                visibleSlides={1}
                                ref={(_whirligigInstance) => { whirligig = _whirligigInstance }}
                                preventScroll='false'
                                className="rounded slider-box"
                                style={{ maxHeight: 'calc(100vh - 100px)' }}
                            >
                                {
                                    oldImages?.map((res, index) => (
                                        <img key={index} className='h-100 w-100 object-fit-contain' src={res?.filePath} alt="" />
                                    ))
                                }
                                {
                                    oldVideos?.map((res, index) => (
                                        <Video key={index} className="h-100 w-100 object-fit-contain" autoPlay loop controls={[]} muted>
                                            <source src={res.filePath} type='video/mp4' />
                                        </Video>
                                    ))
                                }
                            </Whirligig>
                            <i onClick={prev} className='indicator-l bi bi-caret-left fs-3' role='button'></i>
                            <i onClick={next} className='indicator-r bi bi-caret-right fs-3' role='button'></i>
                        </Modal>
                        <div className='col-12 text-end'>
                            {
                                isEditable ?
                                    <button type='button' className='btn btn-danger me-3' onClick={cancelEditing}>Cancel</button> : ''
                            }
                            {
                                isEditable ?
                                    <button type='submit' className='btn btn-primary'>Submit</button> : ''
                            }
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-12 text-end'>
                            {
                                isEditable ? '' :
                                    <button type='button' className='btn btn-danger me-3' onClick={handleDelete}>Delete</button>
                            }
                            {
                                isEditable ? '' :
                                    <button type='button' className='btn btn-primary' onClick={() => { setIsEditable(true) }}>Edit</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ViewProperty;